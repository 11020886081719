<template>
  <div class="select-img-component">
    <el-dialog
      v-model="dialogVisible"
      :title="$t('images.Select Image')"
      width="82vw"
      :before-close="handleClose"
      :visible.sync="dialogVisible"
    >
      <div class="select-img">
        <el-table
          ref="singleTableRef"
          :data="dataList"
          style="width: 100%"
          height="50vh"
          highlight-current-row
          @current-change="handleCheck"
          @sort-change="handleSort"
          border
        >
          <el-table-column type="index" width="50">
            <!-- <el-table-column type="selection" width="55" /> -->
            <el-table-column  width="50">
              <template #default="scope">
                <div style="display: flex; align-items: center;">
                  <el-radio-group v-model="currentRow.imageUrl">
                    <el-radio :label="scope.row.imageUrl" size="large"><span style="display: none;">1</span></el-radio>
                  </el-radio-group>
                </div>
              </template>
            </el-table-column>
          </el-table-column>

          <el-table-column prop="store_name"  min-width="140" :label="$t('images.Store name')" header-align="center">
            <el-table-column prop="storeName" min-width="140" align="center">
              <template slot="header" slot-scope="scope">
                <el-input size="mini" v-model="searchParams.storeName" @input="getData" placeholder="" clearable></el-input>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="image_url" min-width="140"  :label="$t('images.Image')" sortable="custom" header-align="center">
            <el-table-column prop="imageName" min-width="140" align="center">
              <template #header>
                <el-input size="mini" v-model="searchParams.imageName" @input="getData" placeholder="" clearable></el-input>
              </template>
              <template #default="scope">
                <div class="show-img-bd">
                  <div class="img-container">
                    <img :src="scope.row.imageUrl" class="show-img" />
                  </div>
                  <div>{{scope.row.imageName}}</div>
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="image_group_id" :label="$t('images.Group')" sortable="custom" min-width="120" header-align="center">
            <el-table-column prop="groupName" align="center" min-width="120">
              <template slot="header" slot-scope="scope">
                <!-- <el-input size="mini" v-model="searchParams.groupName" @input="getData" placeholder=""></el-input> -->
                <el-select v-model="searchParams.groupId" placeholder=""  size="small" @change="getData" clearable>
                  <el-option
                    v-for="item in groupList"
                    :key="item.imageGroupId"
                    :label="item.groupName"
                    :value="item.imageGroupId"
                  >
                    <div class="d-flex justify-content-between">
                      <div class="">{{item.groupName}}</div>
                      <div style="color:#ccc">{{item.storeName}}</div>
                    </div>
                  </el-option>
                </el-select>

              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="imageTagsName" :label="$t('images.Tags')" header-align="center"> 
            <!-- <el-table-column prop="imageTagsName" label="Tags" sortable="custom">  -->
            <el-table-column prop="imageTags" align="center">
              <template slot="header" slot-scope="scope">
                <el-input size="mini" v-model="searchParams.tagName" @input="getData" placeholder="" clearable></el-input>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="image_size" :label="$t('images.Image Size')" min-width="120" sortable="custom" header-align="center">
            <el-table-column prop="imageSize" min-width="120" align="center">
              <template slot="header" slot-scope="scope">
                <el-input size="mini" v-model="searchParams.imageSize" @input="getData" placeholder="" clearable></el-input>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="image_suffix" :label="$t('images.Format')" sortable="custom" width="110" header-align="center">
            <el-table-column prop="imageSuffix" width="110" align="center">
              <template slot="header" slot-scope="scope">
                <el-input size="mini" v-model="searchParams.format" @input="getData" placeholder="" clearable></el-input>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column prop="create_time" :label="$t('images.Create Time')" sortable="custom" width="230" header-align="center">
            <el-table-column prop="createTime" width="230" align="center">
              <template slot="header" slot-scope="scope">
                <el-date-picker
                  v-model="searchParams.date"
                  type="daterange"
                  size="small"
                  value-format="yyyy-MM-dd"
                  @change="handleDateChange"
                  placement="bottom-start"
                />
              </template>
            </el-table-column>
          </el-table-column>
        </el-table>

        <!-- 页码 -->
        <pagination :tableInfo="pageParams" @changePage="getData" />

        <div class="select-img-btn">
          <el-button size="small" @click="dialogVisible = false">{{$t('button.Cancel')}}</el-button>
          <el-button size="small" color="#28C76F" type="success" @click="handleSave">{{$t('button.Save')}}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { getImageList, getImageGroupList } from "@/libs/api/system-management-images";
  import pagination from '@/components/pagination'
  export default {
    name:'imgSelect',
    components:{
      pagination
    },
    props: {
      color: {
        type: [String, Number],
        default: '',
      }
    },
    data() {
      return {
        dialogVisible:false,
        dataList:[],
        groupList:[],
        searchParams:{
          storeQueryType:'0', ////门店查询类型 ,0：所有门店，1：子门店，2：当前门店
          storeName:'',
          imageName:'',
          groupId:'', // 分组id
          imageSize:'',
          tagName:'',
          format:'',
          date:[],
          orders:[],//['desc:image_url']
          startDate:'', //开始时间
          endDate:'', // 结束时间
          isEffect:0
        },
        pageParams:{
          current:1,//当前页
          size:10,//单页显示最大数
          total:0,
          pageSizeArr:['10','20','50','100','200','500','1000'],
        },
        currentRow:{
          imageUrl:''
        },
        checked:false,
        loading:false
      }
    },
    created(){
    },
    methods: {
      open(){
        this.dialogVisible = true
        this.currentRow = {
          imageUrl:''
        }
        this.initData()
      },
      initData(){
        this.getData()
        this.getImageGroupList()
      },
      // 获取数据
      async getData(){
        try {
          let {
            imageName,
            storeName,
            groupId,
            imageSize,
            tagName,
            format,
            orders,
            startDate,
            endDate,
            isEffect,
            storeQueryType
          } = this.searchParams
          const params ={
            storeId: localStorage.getItem('storeId'),
            storeType: storeQueryType,
            imageName,
            storeName,
            groupId,
            imageSize,
            tagName,
            format,
            orders,
            startDate,
            endDate,
            isEffect,
            current: this.pageParams.current,
            size: this.pageParams.size, //10，20，50，100. 
            color:this.color
          }
          let { data } = await getImageList(params);
          console.log('获取图片数据',data);
          if(data.state){
            const list = data.data.records;
            this.dataList = list;
            this.pageParams.total = data.data.total
          } else {
            this.$message.error(data.message)
            // alert(data.message)
          }
        } catch (error) {
          this.$message.error(error.message)
        }
      },
      //获取 --- 素材组
      async getImageGroupList(param){
        try {
          let userData = JSON.parse(localStorage.getItem('userData'))
          let storeId = localStorage.getItem('storeId')
          let storeQueryType = this.searchParams.storeQueryType
          const params ={
            param: '',
            merchantId: userData.merchantId,
            storeId: storeId || userData.storeId,
            storeQueryType,
            isEffect: 0,
            storeName: '',
            current: 1,
            size: 9999,
            orders: []
          }
          let { data } = await getImageGroupList(params);
          if(data.state){
            this.groupList = data.data
          } else {
            this.$message.error(data.message)
            // alert(data.message)
          }
        } catch (error) {
          this.$message.error(error.message)
        }
      },
      // 排序
      handleSort(column){
        console.log('排序',column)
        let arr = []
        // this.search.orders = column.prop + '_' + column.order;
        if(column.order){
          let str1= column.order === "ascending" ? "asc" : "desc";
          str1 = str1 + ':'+ column.prop;
          arr.push(str1)
        } else {
          arr =[]
        }
        
        this.searchParams.orders = arr;
        this.getData()
      },
      // 时间选择change
      handleDateChange(val){
        console.log('时间选择---',val)
        if(val){
          this.searchParams.startDate = val[0];
          this.searchParams.endDate = val[1];
        } else {
          this.searchParams.startDate = '';
          this.searchParams.endDate =  '';
        }
        
        this.getData()
      },
      // 表格单选
      handleCheck(val){
        console.log('表格选中了...',val)
        if (val) this.currentRow = val
      },
      // 关闭弹窗
      handleClose(){
        this.dialogVisible = false
      },
      // 点击保存
      handleSave(){
        const currentRow = this.currentRow;
        if(currentRow.imageUrl){
          this.dialogVisible = false
          this.$emit('handleConfirm',currentRow)
        } else {
          this.$message({
            message: this.$t('images.ErrorMessage3'),
            type:'warning',
          });
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
.select-img-component{
  ::v-deep .el-table__body-wrapper{
    // min-height: 400px;
    overflow-y: auto;
  }
  .select-img{
    // margin: 0 -100px;
    padding-bottom: 40px;
    margin-top: -10px;
  }
  .select-img-pagination{
    display: flex;
    justify-content: right;
    padding: 20px 0 50px;
  }
  .select-img-btn{
    // width: 200px;
    // margin: 0 auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 80px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    // box-shadow: 0 0 4px rgb(0 0 0 / 30%);
    z-index: 99;
  }
  .custom-check{
    width: 18px;
    height: 18px;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
  }
  .show-img-bd{
    display: flex;
    flex-direction: column;
  }
  .show-img{
    width: 20px;
    height: 20px;
  }

  // 重置样式
  ::v-deep .el-table--border .el-table__cell{
    border-right:1px solid #EBEEF5 !important;
  }
  ::v-deep .el-dialog {
    margin-top: 5vh!important;
  }
  ::v-deep .el-date-editor.el-input__inner{
    width: 206px;
  }
  ::v-deep  .el-button{
    width: 100px;
  }
  ::v-deep .el-table__row{
    cursor: pointer;
  }

}
</style>