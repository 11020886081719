<template>
  <div class="record-wrap">
    <el-dialog
      class="record-dialog"
      :title="$t('itemsScenarios.RecordDialog.title')"
      :visible.sync="showRecordDialog"
      :before-close="handleCloseRecordDialog"
      :append-to-body="true"
      :close-on-click-modal="false"
      width="1350"
      table-layout="fixed"
      border
      style="width: 100%"
      >
      <span class="record" style="display:inline-block;height: 30px;">{{$t('itemsScenarios.RecordDialog.Records')}}: {{ this.tableInfo.total }}</span>
      <div style="height:500px">
        <el-scrollbar style="height:100%">
          <el-table
            style="width: 100%;margin-bottom: 20px;"
            :data="tableList"
            >
            <el-table-column
              type="index"
              :label="$t('itemsScenarios.RecordDialog.No')"
              width="50">
            </el-table-column>

            <el-table-column
              :label="$t('itemsScenarios.RecordDialog.itemName')"
              prop="itemName"
              width="180"
            >
            </el-table-column>  

            <el-table-column
              :label="$t('itemsScenarios.RecordDialog.Modifications')"
              min-width="240"
            >
              <template slot-scope="scope">
                <div v-html="scope.row.diffMsg"></div>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('itemsScenarios.RecordDialog.ModifiedBy')"
              prop="createUser"
              width="180"
            >
            </el-table-column>
            <el-table-column
              :label="$t('itemsScenarios.RecordDialog.ModificationsTime')"
              prop="createTime"
              width="180"
            >
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>
      <pagination :tableInfo="tableInfo" @changePage="changePage" />
    </el-dialog>
  </div>
</template>
<script>
import { getShotsnapList } from '@/libs/api/scenario-item'
import pagination from '@/components/pagination'

export default {
  components: {
    pagination,
  },
  data() {
    return {
      tableList: [],
      tableInfo:{
        current: 1,
        size: 10,
        total: 0
      },
    }
  },
  props: {
    showRecordDialog: {
      default: false,
      type: Boolean,
    },
    itemId: {
      default: '',
      type: String,
    },
  },
  mounted() {
  },
  watch: {
    showRecordDialog(val) {
      if (val) {
        console.log('val======', val)
        this.getList()
      }
    }
  },
  methods: {
    // 翻页
    changePage() {
      this.getList()
    },
    handleCloseRecordDialog() {
      this.$emit('handleCloseRecordDialog')
    },
    async getList() {
      const params = {
        itemId: this.itemId,
        current: this.tableInfo.current,
        size: this.tableInfo.size,
      }
      const res = await getShotsnapList(params)
      if (res.status === 200 && res?.data?.code === 1) {
        let { data } = res.data
        this.tableList = data.records
        this.tableInfo.total = data.total
        console.log('tableList=======', this.tableList)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__wrapper.record-dialog > .el-dialog__body{
  padding: 20px 20px !important;
}
.common-page{
  padding: 0 !important;
}
</style>