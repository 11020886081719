<template>
  <div class="record-dialog">
    <el-dialog
      :title="itemStatus === 'new' ? $t('itemsScenarios.EditDialog.NewItem') : $t('itemsScenarios.EditDialog.EditItem')"
      :visible.sync="showEditDialog"
      :before-close="handleClose"
      :close-on-click-modal="false"
      width="800px"
      >

      <el-form
        class="cont"
        ref="ruleForm"
        >
          <div style="height: 500px">
            <el-scrollbar style="height:100%" >
              <div ref="group-val" v-for="(groupItem,groupIndex) in groupList" :key="groupIndex" style="margin-top: 20px">
                <div v-if="groupItem.fieldList.length >  0">
                  <span class="title">{{ groupItem.translateTextName }}</span>
                  <el-form-item v-for='(fieldItem, fieldIndex) in groupItem.fieldList' :key="fieldIndex">
                    <span class="require-icon" v-if="fieldItem.isRequired">*</span>
                    <el-form-item class="search-label" :label="fieldItem.translateTextName" :prop="fieldItem.columnName" />
                    <!-- :type="fieldItem.type === 'number' ? 'number' : 'text'" -->
                    <el-input 
                      clearable
                      style="width:100%"
                      class= "search-label"
                      size="small"
                      :disabled="fieldItem.canEdit === 0"
                      v-if="(fieldItem.type === 'text' || fieldItem.type === 'textarea' || fieldItem.type === 'number' || fieldItem.type === 'url' || (fieldItem.type === 'img' && !fieldItem.imageType)) && fieldItem.fieldKey !== 'template_id' && fieldItem.fieldKey !== 'owner_id'"
                      ref="search-val"  
                      :type="fieldItem.type"
                      :search-val="fieldItem" 
                      v-model="fieldItem.searchVal" 
                      @input="getInputVal(groupIndex, fieldIndex)"
                    />

                    <div class="scenario-form-language-box" v-else-if="fieldItem.type === 'img' && fieldItem.imageType === 1 && fieldItem.fieldKey !== 'template_id'">
                      <el-input
                        clearable
                        size="small"
                        class="search-label inputwidth"
                        :disabled="fieldItem.canEdit === 0"
                        ref="search-val"  
                        :search-val="fieldItem" 
                        v-model="fieldItem.searchVal" 
                      >
                        <!-- <template #append>
                          <div class="scenario-form-language" @click="showImgDialog(groupIndex, fieldIndex, 'imgLibrary')">{{$t('itemsScenarios.EditDialog.Browse')}}...</div>
                        </template> -->
                      </el-input>
                      <span class="scenario-form-language" @click="showImgDialog(groupIndex, fieldIndex, 'imgLibrary')">{{$t('itemsScenarios.EditDialog.Browse')}}...</span>
                    </div>
                    
                    <el-date-picker
                      size="small"
                      style="width:100%"
                      :disabled="fieldItem.canEdit === 0"
                      class="search-label"
                      ref="search-val"
                      v-else-if="fieldItem.type === 'date' && fieldItem.fieldKey !== 'template_id' && fieldItem.fieldKey !== 'owner_id'"
                      :search-val="fieldItem" 
                      @input="getInputVal(groupIndex, fieldIndex)"
                      v-model="fieldItem.searchVal" 
                      placement="bottom-start"
                      type="datetime"
                      format="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                    />

                    <el-select 
                      style="width:100%"
                      multiple
                      size="small"
                      class="search-label"
                      ref="search-val"
                      v-else-if="fieldItem.type === 'checkbox' && fieldItem.fieldKey !== 'template_id'&& fieldItem.fieldKey !== 'owner_id'" 
                      :disabled="fieldItem.canEdit === 0" 
                      :search-val="fieldItem"  
                      v-model="fieldItem.searchVal" 
                      @input="getInputVal(groupIndex, fieldIndex)"
                      >
                      <el-option
                        v-for="(item,checkIndex) in fieldItem.checkBoxList"
                        :key="checkIndex"
                        :label="item.text"
                        :value="item.text">
                      </el-option>
                    </el-select>

                    <div class="template-box"  v-else-if="fieldItem.fieldKey === 'template_id'&& fieldItem.fieldKey !== 'owner_id'" >
                      <el-input 
                          readonly
                          size="small"
                          class="search-val"
                          ref="search-val"  
                          prop="templateName"
                          :search-val="fieldItem" 
                          v-model="fieldItem.searchVal" 
                          @input="getInputVal(groupIndex, fieldIndex)"
                       />
                        <span class="template-action" @click="choseTemplate(groupIndex,fieldIndex,fieldItem.columnName)" >...</span>
                        <img v-if="fieldItem.searchVal" class="el-icon-close" @click="clearTemplate(groupIndex,fieldIndex,fieldItem.columnName)" src="@/assets/img/clear-cont.png" alt="">
                      <!-- <el-tooltip label="templateName" popper-class="my-template-popper" prop="templateName" placement="bottom" effect="light">
                        <span class="template-action">...</span>
                        <div slot="content" style="cursor:pointer;">
                          <p @click="choseTemplate(groupIndex,fieldIndex,fieldItem.columnName)">{{$t('itemsScenarios.EditDialog.ChoseTemplate')}}</p>
                          <p @click="clearTemplate(groupIndex,fieldIndex,fieldItem.columnName)">{{$t('itemsScenarios.EditDialog.ClearTemplate')}}</p>
                        </div>
                      </el-tooltip> -->
                    </div>
                    <div class="owner-box" v-else-if="fieldItem.fieldKey === 'owner_id'">
                      <el-input
                        class="search-label"
                        size="small"
                        :disabled="fieldItem.canEdit === 0"
                        ref="search-val"
                        :search-val="fieldItem" 
                        v-model="fieldItem.searchVal"
                        @input="getInputVal(groupIndex, fieldIndex)"
                      />
                      <span class="owner-action" @click="choseOwner(groupIndex,fieldIndex,fieldItem.columnName)" >...</span>
                      <!-- <i v-if="fieldItem.searchVal" @click="clearOwner(groupIndex,fieldIndex,fieldItem.columnName)" class="el-icon-close"></i> -->
                      <img v-if="fieldItem.searchVal" class="el-icon-close" @click="clearOwner(groupIndex,fieldIndex,fieldItem.columnName)" src="@/assets/img/clear-cont.png" alt="">
                    </div>
                    <!-- <span class="error-tip" v-show="isShowErrorTip && fieldItem.isRequired && fieldItem.searchVal === ''">{{$t('itemsScenarios.EditDialog.ItemRequireTips')}}</span> -->
                    <span class="error-tip" v-show="isShowErrorTip && fieldItem.isRequired && (!fieldItem.searchVal || (fieldItem.type === 'checkbox' && fieldItem.searchVal.length === 0))">{{$t('itemsScenarios.EditDialog.ItemRequireTips')}}</span>
                  </el-form-item>
                </div>
                
              </div>
            </el-scrollbar>
          </div>
          
      </el-form>

      <!-- 选择模版 -->
      <!-- <template-dialog :showTemplateDialg="showTemplateDialg" @handleTemplateClose="handleTemplateClose" /> -->

      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-btn" @click="handleClose">{{$t('button.Cancel')}}</el-button>
        <!-- <el-button v-if="itemStatus === 'new'" class="save-btn" @click="create">{{$t('confirm')}}</el-button>
        <el-button v-else  class="save-btn" @click="update">{{$t('confirm')}}</el-button> -->
        <el-button class="save-btn" @click="save">{{$t('button.Confirm')}}</el-button>
      </span>
    </el-dialog>

    <!-- 选择模板 -->
    <template-dialog ref="templateDialog" @onTemplateConfirm="handleTemplateClose" :scenarioId = "scenarioId" />

    <!-- 选择归属人 -->
    <select-owner-dialog ref="ownerDialog" :storeType="storeType" @onOwnerConfirm="handleOwnerClose" />

    <!-- 选择图片 -->
      <imgSelect 
        @handleConfirm="selectImg"
        ref="selectImgRef"
      >
      </imgSelect>
  </div>
</template>
<script>
import { getScenario,getItemDetail,createItemList,updateItemList } from '@/libs/api/scenario-item'
import templateDialog from '@/components/select-template/index'
import selectOwnerDialog from '@/components/select-owner/index.vue'

import imgSelect from '@/components/select-img/index.vue'
import {dateFormat} from '@/utils/common.js'
// columnName为列表数据框的id标识  searchVal 为输入框的内容
export default {
  data() {
    return {
      isShowErrorTip: false,// 避免新建时页面出现 errorTip, 首次打开弹框默认不校验，点击保存再校验
      tableList: [],
      groupList: [], // 新建编辑 分组展示需要的list
      dynamicFields: {},
      templateInfo: {},
      ownerInfo: {},
      imgLibrary: {},
    }
  },
  components: {
    templateDialog,
    imgSelect,
    selectOwnerDialog
  },
  props: {
    showEditDialog: {
      default: false,
      type: Boolean,
    },
    scenarioId: {
      default: '',
      type: String,
    },
    itemId: {
      default: '',
      type: String,
    },
    itemStatus: {
      default: '',
      type: String,
    },
    storeType: {
      default: '',
      type: String,
    }
  },
  mounted() {
  },
  watch: {
    itemStatus: {
      handler(val) {
        if (val === '') return
        if (val === 'new') {
          this.getScenarioInfo()
          this.isShowErrorTip = false
        } else if (val === 'edit' && this.itemId) {
          this.itemDetail()
          this.isShowErrorTip = false
        }
      },
      // immediate: true
    }
  },
  methods: {
    // 关闭模版弹框
    handleTemplateClose(val2) {
      console.log('val2=========', val2)

      const currentTemplate = this.groupList[this.templateInfo.groupIndex]['fieldList'][this.templateInfo.fieldIndex]
      currentTemplate.searchVal = val2.templateName
      currentTemplate.templateId = val2.templateId
      this.dynamicFields[this.templateInfo.columnName] = val2.templateId
      
      console.log('tempage--------',this.groupList)
    },
    // 选择模版
    choseTemplate(groupIndex,fieldIndex, columnName) {
      this.templateInfo = {
        groupIndex: groupIndex,
        fieldIndex: fieldIndex,
        columnName: columnName
      }
      this.$refs.templateDialog.show()
      // this.showTemplateDialg = true
    },
    // 清除模版
    clearTemplate(groupIndex,fieldIndex, columnName) {
      console.log('点击清楚模版columnName', columnName)
      // columnName值： 点击清楚之前保存当前的columnName，若选择新模版再删除之后，则恢复原始值
      const currentTemplate = this.groupList[this.templateInfo.groupIndex]['fieldList'][this.templateInfo.fieldIndex]
      currentTemplate.searchVal = ''
      currentTemplate.columnName = columnName
      currentTemplate.templateId = ''
    },
    // 关闭归属人弹框
    handleOwnerClose(val2) {
      console.log('val2=========', val2)
      console.log('handleOwnerCloseownerInfo=========', this.ownerInfo)
      const currentOwner = this.groupList[this.ownerInfo.groupIndex]['fieldList'][this.ownerInfo.fieldIndex]
      console.log('currentOwner', currentOwner)
      let arr
      if (currentOwner.searchVal) {
        // arr = [currentOwner.searchVal]
        arr = currentOwner.searchVal.split(',')
      } else {
        arr = []
      }
      val2.forEach(item => {
        arr.push(item)
      })
      console.log('arr=========', arr)
      arr = [...new Set(arr)]
      console.log('arr222222=========', arr)
      currentOwner.searchVal = arr.join(',')
      currentOwner.loginId = arr.join(',')
      this.dynamicFields[this.ownerInfo.columnName] = arr.join(',')
      
      console.log('currentOwnerpage--------',this.groupList)
    },
    // 选择归属人
    choseOwner(groupIndex,fieldIndex, columnName) {
      this.ownerInfo = {
        groupIndex: groupIndex,
        fieldIndex: fieldIndex,
        columnName: columnName
      }
      this.$refs.ownerDialog.show()
      console.log('choseOwnerownerInfo=========', this.ownerInfo)
    },
    // 清除归属人
    clearOwner(groupIndex,fieldIndex, columnName) {
      console.log('清除归属人columnName', columnName)
      // columnName值： 点击清楚之前保存当前的columnName，若选择新归属人再删除之后，则恢复原始值
      const currentOwner = this.groupList[this.ownerInfo.groupIndex]['fieldList'][this.ownerInfo.fieldIndex]
      currentOwner.searchVal = ''
      currentOwner.columnName = columnName
      currentOwner.loginId = ''
    },
    // 新建 - 获取场景详情字段
    async getScenarioInfo() {
       const params = {scenarioId: this.scenarioId, itemId: this.itemId}
       const res = await getScenario(params)
       if (res.status === 200 && res?.data?.code === 1) {
         const { data } = res.data
         this.groupList = data.groupList
         this.groupList.forEach(item => {
           item.fieldList = item.fieldList.filter(t => t.isVisible).map(t => ({ ...t, searchVal: t.type === 'checkbox' ? (t.defaultValue && t.defaultValue.split(',') || []) :(t.defaultValue || ''), [t.columnName]: t.columnName}) )
         })
        //  this.groupList.forEach(item => {
        //    item.fieldList.forEach(t => {
        //      this.$set(t, 'searchVal', '')
        //      t[t.columnName] = t.columnName
        //    })
        //  })
        console.log('this.groupList', this.groupList)
       } else {
         this.$message({
            message: res?.data?.message,
            type: 'warning'
          });
       }
    },
    // 选择图片--弹窗
    showImgDialog(groupIndex, fieldIndex, imgLibrary){
      this.imgLibrary = {
        imgGroupIndex: groupIndex,
        imgFieldIndex: fieldIndex,
        imgType: imgLibrary
      }
      this.$refs.selectImgRef.open()
    },
    // 选择图片
    selectImg(obj){
      console.log('选择的图片',obj)
      this.getInputVal(this.imgLibrary.imgGroupIndex,this.imgLibrary.imgFieldIndex,this.imgLibrary.imgType, obj.imageUrl)
    },
    // 编辑 - 获取列表详
    async itemDetail() {
      const params = {
        itemId: this.itemId,
        scenarioId: this.scenarioId
      }
      const res = await getItemDetail(params)
      if (res.status === 200 && res?.data?.code === 1) {
         const { data } = res.data
         this.groupList = data?.scenario?.groupList
         this.dynamicFields = data?.dynamicFields
         const templateName = data?.templateName || ''
        //  this.groupList.forEach(item => {
        //    item.fieldList.forEach(t => {
        //      this.$set(t, 'searchVal', '')
        //      if (t.columnName.includes('field')) {
        //        t.searchVal = this.dynamicFields[t.columnName]
        //      } else {
        //        t.searchVal = t.columnName
        //      }
        //      t[t.columnName] = t.columnName
        //    })
        //  })
        this.groupList.forEach(item => {
           item.fieldList = item.fieldList.filter(t => t.isVisible).map(p => {
             this.$set(p, 'searchVal', '')
             this.$set(p, 'templateId', '')
             this.$set(p, 'loginId', '')

            //  this.$set(p, p.columnName, '')
            if (p.fieldKey === 'owner_id'){
              // p.searchVal = p.loginId
              p.loginId = this.dynamicFields[p.columnName]
              p.searchVal = p.loginId
            } else if (p.fieldKey === 'template_id') { // 模版类型时的内容返现为templateName字段，templateName在dynamicFields同级
              p.searchVal = templateName
              p.templateId = this.dynamicFields[p.columnName]
            } else if (p.defaultValue && this.dynamicFields[p.columnName] === ''){ // defaultValue为设置的默认值，this.dynamicFields[p.columnName]为用户自己输入的值
              // p.searchVal = p.defaultValue
              if (p.type === 'checkbox') {
                p.searchVal = p.defaultValue.split(',')
              } else {
                p.searchVal = p.defaultValue
              }
            } else if (p.columnName.includes('field')) {
              // if (p.type === 'date') {
              //   console.log('this.dynamicFields[p.columnName]',this.dynamicFields[p.columnName])
              //   p.searchVal = dateFormat(new Date(Number(this.dynamicFields[p.columnName])), "yyyy-MM-dd HH:mm:ss")
              //   console.log('p.searchVal======',p.searchVal)
              // } else {
              //    p.searchVal = this.dynamicFields[p.columnName]
              // }
              // p.searchVal = this.dynamicFields[p.columnName]
              if (p.type === 'checkbox') {
                p.searchVal = this.dynamicFields[p.columnName]?.split(',') || []
              } else {
                p.searchVal = this.dynamicFields[p.columnName]
              }
            } else {
               p.searchVal = p.columnName
            }
             p[p.columnName] = p.columnName
             return p
           })
         })
         console.log('this.groupList====== ', this.groupList)
       } else {
          this.$message({
            message: res?.data?.message,
            type: 'warning'
          });
        }
    },
    handleClose() {
      this.$emit('handleCloseEditDialog')
    },
    getInputVal(groupIndex, fieldIndex, imgType, imgValue) {
      let count = 0
      for(var i = 0; i < this.groupList.length; i++) {
        if (i < groupIndex) {
          count = count + this.groupList[i]['fieldList'].length
        }
      }
      count = count + fieldIndex
      console.log('count', count)
      console.log('this.$refs[search-val[count]', this.$refs['search-val'][count])

      const columnName = this.$refs['search-val'][count]?.$attrs['search-val']['columnName']
      const searchVal = this.$refs['search-val'][count]?.$attrs['search-val']['searchVal']
      console.log('columnName======',columnName)
      console.log('searchVal======', searchVal)
      console.log('this.dynamicFields======',this.groupList,this.dynamicFields)
      if (imgType === 'imgLibrary') { // 素材库
        this.groupList[groupIndex]['fieldList'][fieldIndex]['searchVal'] = imgValue
      } else {
        this.groupList[groupIndex]['fieldList'][fieldIndex]['searchVal'] = searchVal
      }
      if (columnName) this.dynamicFields[columnName] = searchVal
    },
    save() {
      console.log('保存this.dynamicFields', this.dynamicFields)
      console.log('保存this.group', this.groupList)
      this.isShowErrorTip = true
      let validBlank
      let submitArr = []
      let defaultTextName = ''
      let validLength
      this.groupList.forEach(item => {
        item.fieldList.forEach(t => {
          if (t.type === 'checkbox' && t.isRequired && t.searchVal.length === 0) {
            validBlank = t
            defaultTextName = t.defaultTextName
          } else if (t.isRequired && !t.searchVal) {
            validBlank = t
            defaultTextName = t.defaultTextName
          } else if (t.type === 'text' && t.maxLength && t.searchVal?.length > t.maxLength) {
            defaultTextName = t.defaultTextName
            validLength = t
          }
          submitArr.push({
            columnName: t.columnName,
            columnValue: t.fieldKey === 'template_id' ? t.templateId : (t.type === 'checkbox' ? t.searchVal.join(',') : t.searchVal)
            // columnValue: t.searchVal 
          })
        })
      })
      if (validBlank) {
        // this.$message.warning(`please fillIn ${defaultTextName}`)ItemRequireTips
        // this.$message.warning(this.$t('itemsScenarios.EditDialog.ItemRequireTips'))
        return
      }
      if (validLength) {
        // this.$message.warning(`${defaultTextName} exceed limit`)
        this.$message.warning(this.$t('itemsScenarios.EditDialog.ItemLimitTips'))
        return
      }

      let params
      if (this.itemStatus === 'new') {
        // 新建操作
        params = {
          scenarioId: this.scenarioId,
          dynamicFields: submitArr,
          storeId: localStorage.getItem('storeId') || '',
        }
        console.log('新建操作=====', params)
        createItemList(params).then(res => {
          if (res.status === 200 && res?.data?.code === 1) {
            this.$message.success(this.$t('itemsScenarios.EditDialog.ConfirmItemSuccess'))
          } else {
            this.$message({
              message: res?.data?.message,
              type: 'warning'
            });
          }
        })
      } else {
        // 更新操作
        params = {
          scenarioId: this.scenarioId,
          itemId: this.itemId,
          dynamicFields: submitArr
        }
        console.log('保存操作=====', params)
        updateItemList(params).then(res => {
          if (res.status === 200 && res?.data?.code === 1) {
            this.$message.success(this.$t('itemsScenarios.EditDialog.ConfirmItemSuccess'))
          } else {
            this.$message({
              message: res?.data?.message,
              type: 'warning'
            });
          }
        })
      }
      this.$emit('handleCloseEditDialog', 'confirm')
    },
  }
}
</script>
<style lang="scss" scoped>
.record-dialog{
  .cont{
    // height:761px;
    .title {
      display: inline-block;
      width: 750px;
      height: 40px;
      background: #EEEEEE;
      border-radius: 6px;
      line-height: 40px;
      padding-left: 17px;
    }
    ::v-deep .el-form-item{
      width: 320px;
      margin: 0px  auto;
      // .el-form-item__content{
      //   line-height: 34px;
      // }
      .search-val{
        margin-bottom: 10px;
      }
      .scenario-form-language-box{
        position: relative;
      }
      .scenario-form-language{
        background: #3461FF;
        color: #fff;
        position: absolute;
        right: 0;
        // top: 0;
        // bottom: 0;
        border-radius: 3px;
        padding: 0px 20px;
        cursor: pointer;
        bottom: 3px;
        height: 32px;
        line-height: 32px;
      }
      .template-box{
        position: relative;
        .template-action {
          position: absolute;
          font-size: 20px;
          height: 20px;
          vertical-align: top;
          cursor: pointer;
          top: -4px;
          right: 20px;
        }
        .el-icon-close{
          position: absolute;
          right: 45px;
          top: 10px;
          cursor: pointer;
          width: 12px;
        }
      }
      .owner-box{
        position: relative;
        .owner-action {
          position: absolute;
          font-size: 20px;
          height: 20px;
          vertical-align: top;
          cursor: pointer;
          top: -4px;
          right: 20px;
        }
        .el-icon-close{
          position: absolute;
          right: 45px;
          top: 10px;
          cursor: pointer;
          width: 12px;
        }
        .el-input__inner {
          text-overflow: ellipsis;
          overflow: hidden;
          padding-left: 15px;
          padding-right: 70px !important;
        }
      }
      .require-icon{
        color: red;
        position: absolute;
        left: -20px;
        top: 10px;
      }
      .error-tip{
        display: inline-block;
        color: red;
      }
    }
  }
}
.dialog-footer ::v-deep {
  text-align: center;
  .el-button.cancel-btn {
    background: #d4d4d4 !important;
    box-shadow: 0px 1px 15px 0px rgba(99, 99, 99, 0.23);
    color: #626262;
  }
  .el-button.save-btn {
    background: #35b871 !important;
    box-shadow: 0px 1px 15px 0px rgba(53, 184, 113, 0.55);
    color: #ffffff;
  }
}
.el-dialog__wrapper ::v-deep {
  .el-dialog__body {
    padding: 0 20px 30px !important;
    label{
      margin-bottom: 0 !important;
    }
    .el-form-item__content{
      line-height: 26px;
    }
  }
}
.inputwidth{
  width: 70%;
}
::v-deep .el-form-item__label{
  margin-bottom: 0 !important;
}
::v-deep label{
  margin-bottom: 0 !important;
}
</style>