<template>
  <div class="batch-update-dialog">
    <el-dialog
        :title="$t('itemsScenarios.BatchUpdate')"
        :visible.sync="showUpdateDialog"
        :close-on-click-modal="false"
        :before-close="handleCloseBatchUpdateDialog"
      >
        <el-form ref="form" :model="batchUpdateForm.attributeObj">
          <el-form-item>
            <span class="require-icon">*</span>
          </el-form-item>
          <el-form-item :label="$t('itemsScenarios.Attribute')" prop="value">
            <el-select size="small" ref="search-val" v-model="batchUpdateForm.attributeObj" style="width:100%">
              <el-option
                v-for="(updateItem, updateIndex) in updateList"
                :key="updateIndex" 
                :label="updateItem.label"
                :value="updateItem">
              </el-option>
            </el-select>
            <span v-if="isShowErrorTip" class="error-tip">{{errorTips}}</span>
          </el-form-item>
          <el-form-item>
            <span class="require-icon">*</span>
          </el-form-item>
          <el-form-item :label="$t('itemsScenarios.NewValue')" prop="newValue">
            <el-date-picker
              v-if="batchUpdateForm.attributeObj.fieldType === 'date' && batchUpdateForm.attributeObj.fieldKey !== 'template_id' && batchUpdateForm.attributeObj.fieldKey !== 'owner_id'"
              v-model="batchUpdateForm.attributeObj.newValue"
              style="width:100%"
              size="small"
              clearable
              placement="bottom-start"
              type="datetime"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
            <el-select 
              v-else-if="batchUpdateForm.attributeObj.fieldType === 'checkbox' && batchUpdateForm.attributeObj.fieldKey !== 'template_id' && batchUpdateForm.attributeObj.fieldKey !== 'owner_id'" 
              multiple
              size="small" 
              v-model="batchUpdateForm.attributeObj.newValue"
              style="width:100%"
              >
              <el-option
                v-for="(item,index) in batchUpdateForm.attributeObj.checkBoxList"
                :key="index"
                :label="item.text"
                :value="item.text">
              </el-option>
            </el-select>

            <div 
              v-else-if="batchUpdateForm.attributeObj.fieldKey === 'template_id'&& batchUpdateForm.attributeObj.fieldKey !== 'owner_id'"
              class="template-box" 
              >
              <el-input 
                readonly
                size="small"
                v-model="batchUpdateForm.attributeObj.newValue"
                style="width:100%"
              />
              <span class="template-action" @click="choseTemplate()">...</span>
              <img v-if="batchUpdateForm.attributeObj.newValue" class="el-icon-close" @click="clearTemplate()" src="@/assets/img/clear-cont.png" alt="">
            </div>

            <div 
              v-else-if="batchUpdateForm.attributeObj.fieldKey === 'owner_id'"
              class="owner-box" 
              >
                <el-input
                  size="small"
                  v-model="batchUpdateForm.attributeObj.newValue"
                  style="width:100%"
                />
                <span class="owner-action" @click="choseOwner()" >...</span>
                <img v-if="batchUpdateForm.attributeObj.newValue" class="el-icon-close" @click="clearOwner()" src="@/assets/img/clear-cont.png" alt="">
            </div>

            <div
              v-else-if="batchUpdateForm.attributeObj.fieldType === 'img' && batchUpdateForm.attributeObj.imageType === 1 && batchUpdateForm.attributeObj.fieldKey !== 'template_id'"
              class="scenario-form-language-box" 
              >
                <el-input
                  style="width:215px"
                  clearable
                  size="small"
                  v-model="batchUpdateForm.attributeObj.newValue"
                >
                </el-input>
                <span class="scenario-form-language" @click="showImgDialog()">{{$t('itemsScenarios.EditDialog.Browse')}}...</span>
            </div>

            <el-input
              v-else
              v-model="batchUpdateForm.attributeObj.newValue" 
              size="small"
              :type="batchUpdateForm.attributeObj.fieldType"
              style="width:100%"
              clearable
            />
            <span v-if="isShowNewErrorTip" class="error-tip">{{newErrorTips}}</span>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button class="cancel-btn btn" size="small" @click="handleCloseBatchUpdateDialog('cancel')">{{ $t("button.Cancel") }}</el-button>
          <el-button class="save-btn btn" size="small" @click="handleCloseBatchUpdateDialog('confirm')">{{ $t("button.Submit") }}</el-button>
        </div>
      </el-dialog>
      
      <!-- 选择模板 -->
      <template-dialog ref="templateDialog" @onTemplateConfirm="handleTemplateClose" :scenarioId = "batchUpdateParams.scenarioId" />

      <!-- 选择归属人 -->
      <select-owner-dialog ref="ownerDialog" :storeType="batchUpdateParams.storeType" @onOwnerConfirm="handleOwnerClose" />

      <!-- 选择图片 -->
      <imgSelect @handleConfirm="selectImg" ref="selectImgRef" />
  </div>
</template>
<script>
import { batchUpdate } from '@/libs/api/scenario-item'
import templateDialog from '@/components/select-template/index'
import selectOwnerDialog from '@/components/select-owner/index.vue'
import imgSelect from '@/components/select-img/index.vue'
export default {
  data() {
    return {
      templateInfo: {},
      batchUpdateForm: {
        attributeObj: {
          fieldType: 'text',
          value: '',
          checkBoxList: [],
          newValue:'',
          templateId: '',
          fieldKey: ''
        },
      },
      rules3333: {
        value: [{required: true,message: this.$t('itemsScenarios.BatchUpdateDialog.valueRequireTips'),trigger: 'blur'}],
        newValue: [
          { required: true,message: this.$t('itemsScenarios.BatchUpdateDialog.newValueRequireTips'),trigger: 'blur'},
        ],
      },
      isShowErrorTip: false,
      isShowNewErrorTip: false,
      errorTips: '',
      newErrorTips: ''
    }
  },
  props: {
    showUpdateDialog: {
      default: false,
      type: Boolean
    },
    batchUpdateParams:{
      default: {},
      type: Object
    }
  },
  components: {
    templateDialog,
    imgSelect,
    selectOwnerDialog
  },
  computed: {
    updateList() {
      // return this.batchUpdateParams.headerList.filter(item => item.batchUpdateVisible)
      const arr = []
      this.batchUpdateParams.headerList.forEach(item => {
        if (item.batchUpdateVisible) {
          this.$set(item, 'newValue', '')
          arr.push(item)
        }
      })
      return arr
    },
  },
  watch: {
    'batchUpdateForm.attributeObj.value': {
      handler(val) {
        if (val) {
          console.log('attributeObj', this.updateList)
          this.batchUpdateForm.attributeObj.newValue = ''
        }
      }
    },
  },
  mounted() {
  },
  methods: {
    // 关闭模版弹框
    handleTemplateClose(val) {
      this.batchUpdateForm.attributeObj.newValue = val.templateName
      this.batchUpdateForm.attributeObj.templateId = val.templateId
    },
    // 选择模版
    choseTemplate(columnName, value) {
      this.$refs.templateDialog.show()
    },
    // 清除模版
    clearTemplate() {
      this.batchUpdateForm.attributeObj.newValue = ''
    },
    // 选择归属人
    choseOwner() {
      this.$refs.ownerDialog.show()
    },
    // 清除归属人
    clearOwner() {
      this.batchUpdateForm.attributeObj.newValue = ''
    },
    // 关闭归属人弹框
    handleOwnerClose(val) {
      console.log('val=========', val)
      let arr
      if (this.batchUpdateForm.attributeObj.newValue) {
        arr = this.batchUpdateForm.attributeObj.newValue.split(',')
      } else {
        arr = []
      }
      val.forEach(item => {
        arr.push(item)
      })
      console.log('arr=========', arr)
      arr = [...new Set(arr)]
      this.batchUpdateForm.attributeObj.newValue = arr.join(',')
    },
    showImgDialog() {
      this.$refs.selectImgRef.open()
    },
    // 选择图片
    selectImg(obj){
      console.log('选择的图片',obj)
      this.batchUpdateForm.attributeObj.newValue = obj.imageUrl
    },
    init() {
      this.batchUpdateForm = {
        attributeObj: {
          fieldType: 'text',
          value: '',
          checkBoxList: [],
          newValue:''
        },
      }
      this.isShowErrorTip = false
      this.isShowNewErrorTip = false
      this.errorTips = ''
      this.newErrorTips = ''
    },
    // 校验
    validate(fieldType, value, newValue) {
      if (value === '') {
        this.isShowErrorTip = true
        this.errorTips = this.$t('itemsScenarios.BatchUpdateDialog.newValueRequireTips')
        return false
      }
      if (value !== '') {
        this.isShowErrorTip = false
      }
      if (newValue === '') {
        this.isShowNewErrorTip = true
        this.newErrorTips = this.$t('itemsScenarios.BatchUpdateDialog.newValueRequireTips')
        return false
      }
      if (fieldType === 'number'){
        let reg = new RegExp("^[0-9]*$")
        if (!reg.test(newValue)) {
          console.log('reg', newValue)
          this.isShowNewErrorTip = true
          this.newErrorTips = this.$t('itemsScenarios.BatchUpdateDialog.newValueNumberTips')
          return false
        } else if (newValue.length > 16) {
          this.isShowNewErrorTip = true
          this.newErrorTips = this.$t('itemsScenarios.BatchUpdateDialog.newValueLimitTips')
          return false
        }
      } 
      if (fieldType === 'text' || fieldType === 'url') {
        if (newValue.length > 100) {
          this.isShowNewErrorTip = true
          this.newErrorTips = this.$t('itemsScenarios.BatchUpdateDialog.newValueLimitTips')
          return false
        }
      }
      return true
    },
    async handleCloseBatchUpdateDialog(val) {
      if (val === 'confirm') {
        let dynamicFields = []
        let columnValue
        let { fieldType, fieldKey, value, newValue, templateId } = this.batchUpdateForm.attributeObj

        if (!this.validate(fieldType, value, newValue)) {
          return
        }

        if (fieldKey === 'template_id') {
          columnValue = templateId
        } else if (fieldType === 'checkbox'){
          columnValue = newValue.join(',')
        } else {
          columnValue = newValue
        }
        dynamicFields.push({
          columnName: value,
          columnValue: columnValue
        })
        const params = {
          scenarioId: this.batchUpdateParams.scenarioId,
          itemIds: this.batchUpdateParams.itemIds,
          dynamicFields:dynamicFields
        }
        console.log('params====',params)
        const res = await batchUpdate(params)
        if (res.status === 200 && res?.data?.code === 1) {
          this.$message.success({ message: this.$t('itemsScenarios.BatchUpdateDialog.updateSuccess')});
        } else {
          this.$message.warning({ message: res?.data?.message});
        }
        this.$emit('handleCloseBatchUpdateDialog', 'confirm')
        this.init()
      } else {
        this.$emit('handleCloseBatchUpdateDialog','cancel')
        this.init()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.batch-update-dialog{
  .template-box{
    position: relative;
    .template-action {
      position: absolute;
      font-size: 20px;
      height: 20px;
      vertical-align: top;
      cursor: pointer;
      top: -4px;
      right: 20px;
    }
    .el-icon-close{
      position: absolute;
      right: 45px;
      top: 15px;
      cursor: pointer;
      width: 12px;
    }
  }
  .owner-box{
    position: relative;
    .owner-action {
      position: absolute;
      font-size: 20px;
      height: 20px;
      vertical-align: top;
      cursor: pointer;
      top: -4px;
      right: 20px;
    }
    .el-icon-close{
      position: absolute;
      right: 45px;
      top: 15px;
      cursor: pointer;
      width: 12px;
    }
    .el-input__inner {
      text-overflow: ellipsis;
      overflow: hidden;
      padding-left: 15px;
      padding-right: 70px !important;
    }
  }
  .scenario-form-language-box{
    position: relative;
    .scenario-form-language{
      background: #3461FF;
      color: #fff;
      position: absolute;
      right: 0;
      // top: 0;
      // bottom: 0;
      border-radius: 3px;
      padding: 0px 20px;
      cursor: pointer;
      bottom: 4px;
      height: 32px;
      line-height: 32px;
    }
  }
  .require-icon{
    color: red;
    position: absolute;
    left: -50px;
    top: 12px;
  }
  .error-tip{
    display: inline-block;
    color: red;
    position: absolute;
    left: 0;
    top: 32px;
  }
  ::v-deep.el-form-item{
    display: flex;
    justify-content: center;
    margin-bottom: 10px !important;
    .el-form-item__content{
      width: 300px;
    }
    .el-form-item__label{
      width: 70px;
      text-align: left;
      padding: 0;
    }
    // .el-input__inner{
    //   width: 300px;
    // }
  }
  .dialog-footer ::v-deep {
    text-align: center;
    .el-button {
      width: 110px;
      height: 40px;
    }
    .el-button.cancel-btn {
      background: #d4d4d4 !important;
      box-shadow: 0px 1px 15px 0px rgba(99, 99, 99, 0.23);
      color: #626262;
    }
    .el-button.save-btn {
      background: #35b871 !important;
      box-shadow: 0px 1px 15px 0px rgba(53, 184, 113, 0.55);
      color: #ffffff;
    }
  }
}
</style>