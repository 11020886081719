<template>
  <el-dialog
    :title="$t('exportDialog.Export')"
    width="500px"
    class="export_dialog"
    :close-on-click-modal="false"
    :append-to-body="true"
    :visible.sync="isVisible"
    :before-close="handelCancel"
  >
    <div style="padding: 50px">
      <div class="flex_ flex-column-center flex-row-center">
        <el-radio
          v-for="(item, i) in exportTypeList"
          :key="i"
          v-model="exportType_"
          :label="item.label"
          >{{ item.name }}</el-radio
        >
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button class="cancel-btn btn" size="small" @click="handelCancel"
        >{{ $t("exportDialog.Cancel") }}</el-button
      >

      <el-button
        @click="handelSubmit"
        class="save-btn btn"
        size="small"
        >{{ $t("exportDialog.Submit") }}</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "ExportDialog",
  props: {
    isVisible: {
      type: Boolean,
      require: true,
      default: false,
    },
    exportTypeList: {
      type: Array,
      require: true,
      default: () => [],
    },
    exportType: {
      type: Number,
      require: true,
      default: 2501,
    },
  },

  data() {
    return {
        exportType_: 2501
    };
  },
  created() {
    this.exportType_ = this.exportType;
  },
  methods: {
    async handelSubmit() {
      this.$emit("handelSubmit", this.exportType_);
    },
    handelCancel() {
      this.$emit("handelCancel");
      this.exportType_ = 2501
    },
  },
};
</script>
<style lang="scss" scoped>
.flex_ {
  display: flex;
}
.flex-column-center {
  align-items: center;
}
.flex-row-center {
  justify-content: center;
}

.export_dialog ::v-deep {
  .upload-item {
    margin-top: 40px;
    .label {
      font-size: 18px;
      color: #666666;
      margin-right: 14px;
      margin-top: 9px;
    }
    .el-button {
      width: 315px;
      height: 46px;
      background: #f6f6f6 !important;
      border: 1px solid #dddddd !important;
      border-radius: 4px !important;
      flex: 1;

      color: #666666;
      font-size: 16px;
    }
    .el-button > span {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.dialog-footer ::v-deep {
  text-align: center;
  .el-button {
    width: 110px;
    height: 40px;
  }
  .el-button.cancel-btn {
    background: #d4d4d4 !important;
    box-shadow: 0px 1px 15px 0px rgba(99, 99, 99, 0.23);
    color: #626262;
  }
  .el-button.save-btn {
    background: #35b871 !important;
    box-shadow: 0px 1px 15px 0px rgba(53, 184, 113, 0.55);
    color: #ffffff;
  }
}
</style>