<template>
  <div class="items-wrap">
    <div class="public-breadcrumb">
      {{$t('itemsScenarios.Welcome')}} > {{$t('itemsScenarios.Scenarios')}} >
      <span style="color:#3461FF">{{ subTitle }}</span>
    </div>
    <div class="top">
      <div class="left">
        <el-button class="new-btn" type="primary" size="small" @click="newItem()"> <i class="el-icon-plus"/><span>{{$t('itemsScenarios.New')}}</span></el-button>
        <el-button class="update-btn" type="primary" size="small" @click="batchUpdate()"><i class="el-icon-edit" /><span>{{$t('itemsScenarios.BatchUpdate')}}</span></el-button>
        <el-button class="more-btn" size="small" @click="morePush"><i class="el-icon-check" /><span>{{$t('itemsScenarios.BatchTransmit')}}</span></el-button>
        <el-button class="delete-btn" size="small" @click="deleteItem"><i class="el-icon-delete"/><span>{{$t('itemsScenarios.BatchDelete')}}</span></el-button>
      </div>
      <div class="right">
        <el-button class="import-btn" size="small" @click="importFile">{{$t('itemsScenarios.Import')}}</el-button>
        <el-button class="export-btn" size="small" @click="exportFile">{{$t('itemsScenarios.Export')}}</el-button>
        <!-- <el-button class="share-btn" size="small" @click="shareFile">{{$t('itemsScenarios.Share')}}</el-button> -->
      </div>
    </div>
    <div class="center">
      <div class="center-title">
        <span class="number">{{$t('itemsScenarios.Record')}}: {{ tableInfo.total }}</span>
        <el-select v-model="storeType" style="width: 150px;margin:0 10px" size="small">
          <el-option
            v-for="item in storeOption"
            :key="item.id"
            :label="item.value"
            :value="item.id">
          </el-option>
        </el-select>
        <el-select v-model="viewId" @change="changeView" style="width: 150px" size="small">
          <el-option
            v-for="item in viewList"
            :key="item.id"
            :label="item.viewName"
            :value="item.id">
          </el-option>
        </el-select>

        <el-tooltip placement="bottom" effect="light" popper-class="viewpopverClass">
          <!-- <span class="action">...</span> -->
          <i class="el-icon-more" />
          <div slot="content" class="view-popper" >
            <p @click="newScenario">{{$t('itemsScenarios.New')}}</p>
            <p v-if="viewId !== '1'" @click="editScenario">{{$t('itemsScenarios.Edit')}}</p>
            <p v-if="viewId !== '1'" @click="copyScenario">{{$t('itemsScenarios.Copy')}}</p>
            <p v-if="viewId !== '1'" @click="deleteScenario">{{$t('itemsScenarios.Delete')}}</p>
          </div>
        </el-tooltip>

        <button class="refresh" @click="handleRefresh" ref="btn"><i class="el-icon-refresh"></i></button>
        <!-- <el-popover
          placement="top"
          width="160"
          v-model="visible">
          <p @click="newScenario">新建</p>
          <p v-if="!forbidAction" @click="editScenario">修改</p>
          <p v-if="!forbidAction" @click="copyScenario">复制</p>
          <p v-if="!forbidAction" @click="deleteScenario">删除</p>
        </el-popover> -->
      </div>
      <view-dialog :scenarioStatus="scenarioStatus" :scenarioId="scenarioId" :viewId="viewId" :showViewDialog="showViewDialog" @handleViewClose="handleViewClose"/>
      <edit-dialog :showEditDialog="showEditDialog" :scenarioId="scenarioId" :storeType="storeType" :itemId="itemId" :itemStatus="itemStatus" @handleCloseEditDialog="handleCloseEditDialog"/>
      <el-table
        v-loading="loading"
        style="width:100%;margin-bottom: 20px;"
        :data="tableList"
        @selection-change="handleSelectionChange"
        @sort-change="handleSort"
        center
        >

        <el-table-column
          v-if="headerList.length > 0"
          width="100"
          type="selection"
        >
          <el-table-column
            width="55"
            type="selection"
            style="display:none"
            >
          </el-table-column>
        </el-table-column>

        <el-table-column sortable="custom" align="center" :min-width="headerItem.fieldType === 'date' ? 400 : 180" v-for='(headerItem, headerIndex) in headerList' :key="headerIndex" :label="headerItem.label" :prop="headerItem.value">
          <el-table-column :prop="headerItem.value" :min-width="headerItem.fieldType === 'date' ? 400 : 180">
            <template slot="header" slot-scope="scope">
              <el-input 
                clearable
                size="small"
                v-if="headerItem.fieldType === 'text' || headerItem.fieldType === 'number' || headerItem.fieldType === 'checkbox' || headerItem.fieldType === 'textarea'" 
                ref="search-val" 
                v-model='headerItem.searchVal'
                :search-val="headerItem"
                :type="headerItem.fieldType"
                @input="handleSearchItemTable(headerIndex,$event,headerItem.fieldType)"
              />

              <el-date-picker
                size="small"
                clearable
                v-else-if="headerItem.fieldType === 'date'"
                ref="search-val" 
                v-model="headerItem.searchVal"
                :search-val="headerItem"
                type="daterange"
                placement="bottom-start"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                @input="handleSearchItemTable(headerIndex, $event,headerItem.fieldType)"
              />
              <el-select v-else-if="headerItem.type === 'checkbox'" size="small" ref="search-val" :search-val="headerItem"  v-model="headerItem.searchVal" @input="handleSearchItemTable(headerIndex,$event,headerItem.fieldType)">
                <el-option
                  v-for="item in headerItem.checkBoxList"
                  :key="item.displayOrder"
                  :label="item.text"
                  :value="item.text">
                </el-option>
              </el-select>

            </template>
            <template slot-scope="scope" width="300" class="table-cont">
              <div v-if="headerItem.value === 'itemTags'">
                  <el-popover popper-class="eslpopverClass" ref="pop" placement="right" trigger="click" @show="showPopver(scope.row.itemId)" @hide="hidePopver">
                    <div slot="reference" > {{ scope.row[headerItem.value] }} <i v-if="scope.row[headerItem.value]" class="el-icon-search search-icon pointer"/></div>
                    <!-- <i class="el-icon-more pointer"></i>
                    <i class="el-icon-search search-icon pointer"></i> -->
                    <div class="image-dialog">

                      <div class="title">{{$t('itemsScenarios.ImgDialog.LabelImage')}}</div>
                      <div class="items">
                        <span class="items-title">
                          {{$t('itemsScenarios.ImgDialog.ListAssociatedItems')}}:
                        </span>
                        <div class="item-table">
                          <div class="item-table-header flex-between">
                            <div style="width:50%">{{$t('itemsScenarios.ImgDialog.ESLType')}}</div>
                            <div style="width:50%">{{$t('itemsScenarios.ImgDialog.ESLCode')}}</div>
                          </div>
                          <div class="item-table-cont flex-between">
                            <div style="width:50%">{{ eslDataInfo && eslDataInfo.tagTypeName || '' }}</div>
                            <div style="width:50%">{{ eslDataInfo && eslDataInfo.tagIdentity }}</div>
                          </div>
                        </div>
                        <div class="details">
                            <div class="title">{{$t('itemsScenarios.ImgDialog.ESLDetails')}}:</div>
                            <div class="type">{{ eslDataInfo && eslDataInfo.tagIdentity}}</div>
                            <div class="details-image">
                              <img :src="eslDataInfo && eslDataInfo.imageSrc" alt="" srcset="" />
                            </div>
                        </div>
                        <div class="items-types">
                          <div class="item-type">
                            {{$t('itemsScenarios.ImgDialog.ESLType')}}: {{ eslDataInfo && eslDataInfo.tagTypeName }}
                          </div>
                          <div class="item-type">
                            {{$t('itemsScenarios.ImgDialog.LastAssociated')}}: {{ eslDataInfo && eslDataInfo.updateTime }}
                          </div>
                          <div class="item-type">
                            {{$t('itemsScenarios.ImgDialog.LastUpdate')}}: {{ eslDataInfo && eslDataInfo.updateTime }}
                          </div>
                        </div>
                        <div class="btn-arrow">
                          <i class="el-icon-arrow-left" @click="lastPage"/>
                          <i class="el-icon-arrow-right" @click="nextPage"/>
                        </div>
                      </div>

                    </div>
                  </el-popover>
              </div>
              <div class="cont-img" v-else-if="headerItem.fieldType === 'img'">
                <img :src="scope.row[headerItem.value]" alt="">
              </div>
              <div v-else>
                {{ scope.row[headerItem.value] }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column
          v-if="headerList.length > 0"
          class="action"
          :label="$t('itemsScenarios.Action')"
          width="130"
          fixed="right"
          >
            <el-table-column
               width="130"
              >
              <template slot-scope="scope">
                <img class="el-icon-help" src="@/assets/img/ESL_history_icon.png" @click="checkHistory(scope.row)"/>
                <i class="el-icon-edit" @click="editItem(scope.row)"/>
                <img class="el-icon-help" src="@/assets/img/ESL_wifi.png" @click="itemRepush(scope.row)"/>
              </template>
            </el-table-column>
        </el-table-column>
      </el-table>
      <pagination :tableInfo="tableInfo" @changePage="changePage" />
      <export-dialog 
        :source="'itemPage'"
        :isVisible="showExportDialog"
        :exportType="exportType"
        :exportTypeList="exportTypeList" 
        @handelSubmit="handelExportDialgSubmit" 
        @handelCancel="handelExportDialgCancel"
      />
      <import-dialog 
        :scenarioId="scenarioId"
        :source="'itemPage'"
        :importType="importType"
        :acceptFileType="acceptFileType"
        :isVisible="showImportDialog"
        :importTypeList="importTypeList"
        @handelSubmit="handelSubmitImport"
        @handelCancel="handelimportDialgCancel"
      />
      <import-policy
        :showImportPolicy="showImportPolicy"
        @handleImportPolicy="handleImportPolicy" 
        :scenarioId="scenarioId" 
        :policyData="policyData"
      />
       <confirm-dialog
        :dialogVisible="delectItemVisible"
        @handleClose="handleCloseDelectItem"
        :confirmObj="confirmObj"
      />
      <confirm-dialog
        :dialogVisible="delectViewVisible"
        @handleClose="handleCloseDelectView"
        :confirmObj="confirmObj"
      />
      <batchUpdateDialog :showUpdateDialog="showUpdateDialog" :batchUpdateParams="batchUpdateParams" @handleCloseBatchUpdateDialog="handleCloseBatchUpdateDialog"/>
      <record-dialog :showRecordDialog="showRecordDialog" :itemId="itemId" @handleCloseRecordDialog="handleCloseRecordDialog"/>
    </div>
  </div>
</template>
<script>
import {keepPercentage, handleNumber,keepThousands,keepDecimal} from '@/utils/common.js'
import { debounce } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import ConfirmDialog from '@/components/confirm-dialog/index.vue'
import viewDialog from './view-dialog'
import recordDialog from './record-dialog'
import editDialog from './edit-dialog'
import pagination from '@/components/pagination'
import ExportDialog from "@/components/ExportDialog";
import importDialog from "@/components/ImportDialog/indexSingleFile";
import importPolicy from './import-policy'
import batchUpdateDialog from './batch-update-dialog'
import historyDialog from './record-dialog'
import { commonStore } from '@/store/global-select-store'
import { getViewList, 
         deleteView,
         getItemList,
         getViewDetail,
         getTemplateList,
         repush,
         batchDelete,
         exportItem,
         importForFirst,
         importForSecond,
         getTagListByItemId,
         batchUpdate
  } from '@/libs/api/scenario-item'
export default {
  data() {
    return {
      commonStore,
      delectViewVisible: false,// 删除视图
      delectItemVisible:false, // 删除商品
      confirmObj: {
         type: this.$t('ConfirmDialog.Warning'),
         cancelText: this.$t('ConfirmDialog.ThinkAgain'),
         confirmText: this.$t('ConfirmDialog.Yes'),
         content: this.$t('ConfirmDialog.DeleteTips'),
       },
      loading: true,
      showEslPop: false, // 价签弹框
      showImportPolicy: false,
      policyData: {},
      showExportDialog: false,
      showImportDialog: false,
      importType:'excel',
      importTypeList:[{ label: 'excel', name: 'excel' }, { label: 'csv', name: 'csv' } ],
      acceptFileType:'.xls,.xlsx',
      exportTypeList: [ { name: 'excel', label: 2501 }, { name: 'csv', label: 2502} ],
      exportType: 2501,
      // scenarioId: '1570342756224983040', // '1575022040222846976'    1570342756224983040
      scenarioId: this.$route.params.id,
      canAction: true,
      showEditDialog: false, //  编辑 新建弹框
      tableInfo: {
        current: 1,
        size: 10,
        total: 0,
        orders: []
      },
      tableList: [], // item 表格list
      headerList: [], // item 头部列的list动态集合
      headerListCopy: [],
      storeType: '2',
      storeOption: [{id:'2', value: this.$t('itemsScenarios.ThisStore')},{id: "4", value: this.$t('itemsScenarios.LastStore')}, {id: "1", value: this.$t('itemsScenarios.ZimenStore')},{id:'0', value: this.$t('itemsScenarios.AllStore')}], // 商店list
      showViewDialog: false, // 视图弹框
      viewList:[], // 视图下拉框
      viewId: '1', // 视图选中的id
      visible: false,
      scenarioStatus: '', // 视图操作的状态
      forbidAction: false,
      inputCondtion:{},
      inputCondtionCopy: {},
      itemId: '', // 点击的当前的itemid
      itemStatus: '',
      multipleSelection: [],
      eslDataInfo: {
        type:'',
        tagIdentity: 0,
        imageSrc:'',
        updateTime: ''
      },
      eslCurrentPage: 0,
      eslResult: [],
      init: true, // 是否首次进入页面
      subTitle: '',
      showUpdateDialog: false, // 批量修改
      batchUpdateParams: {
        scenarioId:'',
        itemIds: '',
        storeType: '',
        headerList: [],
      },
      showRecordDialog: false, // 历史记录弹框
    }
  },
  components: {
    viewDialog,
    recordDialog,
    editDialog,
    pagination,
    ExportDialog,
    importDialog,
    importPolicy,
    ConfirmDialog,
    batchUpdateDialog,
    historyDialog
  },
  mounted(){
    console.log('this.$routes.params',  this.$route.params.id)
    commonStore.setInjectCallBack(this.initPage)
  },
  computed:{
    language() {
      return this.$store.state.appConfig.language
    },
  },
  watch: {
    language(val) {
      if(val) {
        this.initPage()
      }
    },
    '$route.params.id': {
      handler(val) {
        if (val) {
          console.log('val', val)
          if (this.$route.params?.subTitle) {
            localStorage.setItem('scenarioSubTitle',this.$route.params?.subTitle)
          }
          this.subTitle = this.$route.params?.subTitle ? this.$route.params.subTitle : (localStorage.getItem('scenarioSubTitle') || '')
          this.scenarioId = val
          this.initPage()
          this.init = true
        }
      },
      immediate: true
    },
    'storeType': {
      handler(val) {
        this.initItemList()
      }
    },
    'viewId': {
      async handler(val) {
        this.inputCondtion = {}
        this.headerList = []
        this.headerList.forEach(item => {
          this.$set(item, 'fieldType', '')
          this.$set(item, 'value', '')
          this.$set(item, 'fieldId', '')
          this.$set(item, 'label', '')
          this.$set(item, 'searchVal', '')
          // if (item.fieldType === 'text' || item.fieldType === 'checkbox' || item.fieldType === 'number') {
          //   item.searchVal = ''
          // } else if (item.fieldType === 'date') {
          //   item.firstVal = ''
          //   item.endVal = ''
          // }
        })
        await this.initItemList('getNewTableHead')
      },
      immediate: true
    }
  },
  methods:{
    initPage() {
      this.initItemList()
      this.initViewList()
    },
    // 刷新
    handleRefresh(){
      this.loading = true
      this.initViewList()
    },
    // 表格排序
    handleSort(column, prop, order){
      console.log('表格排序',column, prop, order)
      let arr = []
      if(column.order){
        let str1= column.order === "ascending" ? "asc" : "desc";
        str1 = str1 + ':'+ column.prop;
        arr.push(str1)
      } else {
        arr = []
      }
      this.tableInfo.orders = arr;
      this.initItemList()
    },
    // 显示价签弹框
    showPopver(currentId) {
      console.log('currentId', currentId)
      this.eslCurrentPage = 0
      this.getTagList(currentId)
    },
    // 隐藏价签弹框
    hidePopver() {
      this.eslCurrentPage = 0
    },
    // 查看价签视图数据
    async getTagList(currentId) {// '1580859741962543104'
      const res = await getTagListByItemId({itemId: currentId})
      if (res.status === 200 && res?.data?.code === 1) {
        this.eslResult = res.data.data
        if (this.eslResult) {
          this.eslDataInfo = this.eslResult[this.eslCurrentPage]
        }
        console.log('eslDataInfo=======', this.eslDataInfo)
      } else {
        this.$message({
          message: res.data.message,
          type: 'success'
        });
      }
    },
    // 价签下一步
    nextPage() {
      if (this.eslCurrentPage === this.eslResult.length - 1){
        this.$message.warning(this.$t('itemsScenarios.ImgDialog.lastPageTips'))
        return
      } else {
        this.eslCurrentPage++
        this.eslDataInfo = this.eslResult[this.eslCurrentPage]
      }
      console.log('this.eslCurrentPage', this.eslCurrentPage)
      console.log('this.eslDataInfo', this.eslDataInfo)
    },
    // 价签上一步
    lastPage() {
      if(this.eslCurrentPage === 0) {
        // this.eslCurrentPage = this.eslResult - 1
        this.$message.warning(this.$t('itemsScenarios.ImgDialog.firstPageTips'))
        return
      } else {
        this.eslCurrentPage --
        this.eslDataInfo = this.eslResult[this.eslCurrentPage]
      }
    },
    // 导入 第二步 保存
    async handleImportPolicy(val) {
      this.showImportPolicy = false
      if (val === 'confirm') {
        this.showImportDialog = false
        this.$forceUpdate();
      }
      this.$forceUpdate();
    },
    // 导入 第一步
    async handelSubmitImport(params) {
      console.log('=handelSubmitImporthandelSubmitImport', params)
      const res = await importForFirst(params)
      if (res.status === 200 && res?.data?.code === 1) {
        this.policyData = res.data?.data
        // 导入 第二步 策略
        this.showImportPolicy = true
      } else {
        this.$message({
          message: res.data.message,
          type: 'success'
        });
      }
    },
    // 导入文件按钮
    importFile() {
      this.importType = 'excel'
      this.acceptFileType = '.xls,.xlsx'
      this.showImportDialog = true;
    },
    // 导入取消
    handelimportDialgCancel(val) {
      this.showImportDialog = false;
    },
    // 导出文件
    exportFile() {
      this.showExportDialog = true
    },
    // 导出文件-取消
    handelExportDialgCancel() {
      this.showExportDialog = false
    },
    // 导出提交
    handelExportDialgSubmit(val) {
      this.showExportDialog = false
      const params = {
        scenarioId: this.scenarioId,
        exportType: val,
        storeId: localStorage.getItem('storeId') || '',
        storeType: this.storeType,
        inputCondtion: this.inputCondtion,
        viewId: this.viewId
      }
      console.log('导出params==========',params)
      let fileName = JSON.parse(localStorage.getItem('userData'))?.storeName || '导出文件'
      fileName = fileName + '-' + Date.now()
      exportItem(params)
        .then(res => {
          const { data } = res
          let downfile
          if (val === 2501) {
            downfile = new File([data], `${fileName}.xlsx`, {
              type: 'application/vnd.ms-excel',
            })
          } else {
            downfile = new File([data], `${fileName}.csv`, {
              type: 'text/csv',
            })
          }
          const tmpLink = document.createElement('a')
          const objectUrl = URL.createObjectURL(downfile)

          tmpLink.href = objectUrl
          tmpLink.download = downfile.name
          document.body.appendChild(tmpLink)
          tmpLink.click()

          document.body.removeChild(tmpLink)
          URL.revokeObjectURL(objectUrl)
        })
        .catch(err => {
          modelUi.msgError(parseError(err))
        })
    },
    // 翻页
    changePage() {
      this.initItemList()
    },
    
    // 以下为item列表部分
    // item 列表部分
    initItemList: debounce(async function(fromSource) {
      this.loading = true
      const params = {
        storeId: localStorage.getItem('storeId') || '',
        storeType: this.storeType,
        scenarioId: this.scenarioId,
        inputCondtion: this.inputCondtion || {},
        viewId: this.viewId, // '1',
        isEffect: 0,
        current: this.tableInfo.current,
        size: this.tableInfo.size,
        orders: this.tableInfo.orders
      }
      const res = await getItemList(params)
      if (res.status === 200 && res?.data?.code === 1) {
        const { data } = res.data
        console.log('initItemList=====', data)
        this.tableInfo.total = data.total
        this.tableList = data.records
        const scenarioData = data.scenario
        const viewData = data.view
        const scenarioDataList = scenarioData.fieldList.concat(scenarioData.staticFields)
        const viewDataList = viewData.viewListField
        console.log('scenarioData=====', scenarioDataList)
        console.log('view=====', viewDataList)
        const headerListCopy = cloneDeep(this.headerList) // 区分搜索查询列表功能
        if (fromSource === 'getNewTableHead') { // 头部header变化时更新列表
         this.headerList = []
          viewDataList.forEach(item => {
            scenarioDataList.forEach(t => {
              if (item.columnName === t.columnName) {
                this.headerList.push({
                  value: item.columnName,
                  fieldType: item.fieldType,
                  fieldId: item.fieldId,
                  sort: item.sort,
                  label: t.translateTextName,
                  searchVal: '',
                  batchUpdateVisible: t.batchUpdateVisible,
                  checkBoxList: t.checkBoxList || [],
                  fieldKey: t.fieldKey || '',
                  canEdit: t.canEdit,
                  imageType: t.imageType
                })
              }
            })
          })
        } else {
          viewDataList.forEach(item => {
            scenarioDataList.forEach(t => {
              if (item.columnName === t.columnName) {
                if (headerListCopy.length === 0) {
                  this.headerList.push({
                    value: item.columnName,
                    fieldType: item.fieldType,
                    fieldId: item.fieldId,
                    sort: item.sort,
                    label: t.translateTextName,
                    searchVal: '',
                    batchUpdateVisible: t.batchUpdateVisible,
                    checkBoxList: t.checkBoxList || [],
                    fieldKey: t.fieldKey || '',
                    canEdit: t.canEdit,
                    imageType: t.imageType
                  })
                }
              }
            })
          })
        }
        console.log('headerList================',this.headerList)
        // this.tableList.forEach(item => {
        //   viewDataList.forEach(t => {
        //     if (t.columnName.includes('field')) {
        //       item[t.columnName] = item.dynamicFields[t.columnName]
        //     }
        //   })
        // })
        this.tableList.forEach(item => {
          scenarioDataList.forEach(t => {
            if (t.columnName.includes('field')) {
              item[t.columnName] = item.dynamicFields[t.columnName]
            }
            if (t.type === 'number' && item.dynamicFields[t.columnName] !== ''){ //处理number类型，有值才会处理
              item[t.columnName] = this.handleNumberType(t, item.dynamicFields[t.columnName])
            }
          })
        })
      } else {
        this.$message({
          message: res?.data?.message,
          type: 'warning'
        });
      }
      this.loading = false
    }, 1000),
    // 处理number类型的数据
    handleNumberType(val, number) {
      if (val.isCustom) {
        number = handleNumber[val.format](number)
      } else {
        if (val.keepDecimalPlaces){
          number = keepDecimal(number, val.keepDecimalPlaces)
        }
        if (val.isDisplayPercentage) {
          number = keepPercentage(number)
        }
        if (val.isDisplayThousands) {
          number = keepThousands(number)
        }
        if (val.prefix) {
          number = val.prefix + number
        }
        if (val.suffix) {
          number = number + val.suffix
        }
      }
      return number
    },
    // 复选框选择
    handleSelectionChange(val) {
      this.multipleSelection = []
      val.forEach(item => {
        this.multipleSelection.push(item.itemId)
      });
      console.log('复选框选择商户========', this.multipleSelection)
    },
    // item - 新建
    newItem() {
      this.itemStatus = 'new'
      this.showEditDialog = true
    },
    // item - 批量更新
    batchUpdate() {
      if (this.storeType === '1') {
        this.$message.warning(this.$t('itemsScenarios.OperateTips'))
        return
      }
      if (this.multipleSelection.length === 0) {
        this.$message.warning(this.$t('itemsScenarios.ActionRequireTips'))
        return
      }
      this.batchUpdateParams = {
        scenarioId:this.scenarioId,
        itemIds: this.multipleSelection?.join() || '',
        headerList: this.headerList,
        storeType: this.storeType
      }
      this.showUpdateDialog = true
    },
    // item - 关闭批量更新弹框
    handleCloseBatchUpdateDialog(val) {
      this.showUpdateDialog = false
      this.itemStatus = ''
      setTimeout(() => {
        if (val === 'confirm') {
          this.initItemList()
        }
      })
    },
    // item - 查看历史记录
    checkHistory(val) {
      this.itemId = val.itemId
      this.showRecordDialog = true
    },
    // item - 关闭历史记录弹框
    handleCloseRecordDialog() {
      this.showRecordDialog = false
    },
    // item - 单项重推
    async itemRepush(val) {
      if (val.bindState === 0) {
        this.$message.warning(this.$t('itemsScenarios.BatchTransmitLimitTips'))
        return
      }
      const params = {
        scenarioId:this.scenarioId,
        itemIds: val.itemId
      }
      const res = await repush(params)
      if (res.status === 200 && res?.data?.code === 1) {
        this.$message.success(this.$t('itemsScenarios.BatchTransmitSuccess'))
        this.initItemList()
      } else {
        this.$message({
          message: res?.data?.message,
          type: 'warning'
        });
      }
    },
    // item - 批量重推
    async morePush() {
      console.log('multipleSelection===', this.multipleSelection)
      let canPush = true
      this.tableList.forEach(item => {
        this.multipleSelection.forEach(t => {
          if (item.itemId === t && item.bindState === 0) {
            canPush = false
          }
        })
      })
      if (this.multipleSelection.length === 0) {
        this.$message.warning(this.$t('itemsScenarios.ActionRequireTips'))
        return
      }
      if (!canPush) {
        this.$message.warning(this.$t('itemsScenarios.BatchTransmitLimitTips'))
        return
      }
      const params = {
        scenarioId:this.scenarioId,
        itemIds: this.multipleSelection?.join() || ''
      }
      const res = await repush(params)
      if (res.status === 200 && res?.data?.code === 1) {
        this.$message.success(this.$t('itemsScenarios.BatchTransmitSuccess'))
        this.initItemList()
      } else {
        this.$message({
          message: res?.data?.message,
          type: 'warning'
        });
      }
    },
    // item - 删除弹框
    async handleCloseDelectItem(val){
      if (val === 'confirm') {
        const params = {
          scenarioId:this.scenarioId,
          ids: this.multipleSelection?.join() || ''
        }
        console.log('复选框选择商户========', this.multipleSelection.join())
        const res = await batchDelete(params)
        if (res.status === 200 && res?.data?.code === 1) {
          this.$message.success(this.$t('itemsScenarios.DeleteSuccess'))
          this.initItemList()
        } else {
          this.$message({
            message: res?.data?.message,
            type: 'warning'
          });
        }
      }
      this.delectItemVisible = false
    },
    // item - 批量删除
   deleteItem() {
     if (this.storeType === '1') {
        this.$message.warning(this.$t('itemsScenarios.OperateTips'))
        return
      }
      if (this.multipleSelection.length === 0) {
        this.$message.warning(this.$t('itemsScenarios.ActionRequireTips'))
        return
      }
      this.delectItemVisible = true
    },
    // item - 重推
    getRepush(val) {
      const params = {
        itemId: val.itemId,
        scenarioId: this.scenarioId
      }
      repush(params).then(res => {
        if (res.status === 200 && res?.data?.code === 1) {
          this.$message.success(this.$t('itemsScenarios.BatchTransmitSuccess'))
          this.initItemList()
        } else {
          this.$message({
            message: res?.data?.message,
            type: 'warning'
          });
        }
      })
    },
    // item - 单个编辑
    editItem(val) {
      console.log('val=======', val)
      if (this.storeType === '1') {
        this.$message.warning(this.$t('itemsScenarios.OperateTips'))
        return
      }
      this.itemId = val.itemId
      this.showEditDialog = true
      this.itemStatus = 'edit'
      console.log('itemId', this.itemId)
    },
    // item - 关闭编辑弹框
    handleCloseEditDialog(val) {
      this.showEditDialog = false
      this.itemStatus = ''
      setTimeout(() => {
        if (val === 'confirm') {
          this.initItemList()
        }
      })
    },
    // item - 模糊搜索 输入框
    handleSearchItemTable(index, val,type) {
      console.log('$event=====', val)
      // console.log('99999', this.$refs['search-val'][index].$attrs['search-val'])
      // headerName： 模糊搜索要传入的inputCondtion对象的key值，searchVal：模糊搜索要传入的inputCondtion对象的value值
      // const item = this.headerList[index];
      // console.log('item=====', item)
      const refDom = this.$refs['search-val'][index].$attrs['search-val']
      if (refDom) { // before
        const obj = {
          headerName: refDom['value'],
          // searchVal: refDom['searchVal'],
          searchVal: val,
          type: refDom['fieldType'],
        }
        console.log('obj.searchVal',obj.searchVal)
        if (type === 'date') obj.searchVal = obj.searchVal?.join(',') || ''
        this.inputCondtion[obj.headerName] = obj.searchVal
        console.log('33333handleSearchItemTable', this.inputCondtion)
        this.$set(this.headerList[index], 'searchVal', val);
        this.initItemList()
      }
    },


    // 以下为视图部分
    //获取视图选择数据list下拉框
    async initViewList() {
      const params = {
        // viewName: this.viewName,
        scenarioId: this.scenarioId
      }
      const res = await getViewList(params)
      if (res.status === 200 && res?.data?.code === 1) {
        const { data } = res.data
        this.viewList = data
        setTimeout(() => {
          this.loading = false
        })
        // 只有首次进入页面才会显示默认值
        if (this.init) this.viewId = this.viewList[0]['id']
      } else {
        this.$message({
          message: res?.data?.message,
          type: 'warning'
        });
      }
      // console.log('this.viewList =========', this.viewList )
    },
    // 视图
    async changeView() {
      // 只有首次进入页面才会显示默认值
      this.init = false
      console.log('viewId', this.viewId)
      // if (this.viewId === '') return
      // const choseView = this.viewList.find(item => item.id === this.viewId)
      // this.canAction = choseView.userId === this.userId
      // if (choseView.defaultType === 0) {
      //    this.forbidAction = true
      // } else {
      //   this.forbidAction = false
      // }
      await this.initItemList()
      console.log('最后的数据处理viewListField', this.viewListField)
      console.log('最后的数据处理tableList', this.tableList)
    },
    async getEditDetail() {
      const params = {id: this.viewId, scenarioId: this.scenarioId}
      const res = await getViewDetail(params)
      if (res.status === 200 && res?.data?.code === 1) {
        const { data } = res.data
        this.viewListField = data.viewListField
      } else {
        this.$message({
          message: res?.data?.message,
          type: 'warning'
        });
      }
    },
    // 视图 - 新建
    newScenario(){
      this.scenarioStatus = 'new'
      this.showViewDialog = true
    },
    // 视图 - 编辑
    editScenario() {
      this.scenarioStatus = 'edit'
      this.showViewDialog = true
    },
    // 视图 - 复制
    copyScenario() {
      this.scenarioStatus = 'copy'
      this.showViewDialog = true
    },
    // 视图 - 删除
    deleteScenario() {
      this.delectViewVisible = true
    },
    handleCloseDelectView(val) {
      if (val === 'confirm') {
        deleteView({id: this.viewId}).then(res => {
          if (res.status === 200 && res?.data?.code === 1) {
            this.$message.success(this.$t('itemsScenarios.DeleteSuccess'))
          } else {
            this.$message({
              message: res?.data?.message,
              type: 'warning'
            });
          }
        })
        this.init = true
        this.initViewList()
      }
      this.delectViewVisible = false
    },
    // 视图 - 关闭弹框
    handleViewClose(val) {
      this.showViewDialog = false
      this.scenarioStatus = ''
      setTimeout(() => {
        if (val === 'confirm') {
          this.initViewList()
          this.initItemList('getNewTableHead')
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.pointer{
  cursor: pointer;
}

.items-wrap{
  .top {
    height: 70px;
    padding: 20px;
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.05);
    border-radius: 6px;
    margin-bottom: 20px;
    background: #fff;
    .left{
      float: left;
      .new-btn,.update-btn, .more-btn, .delete-btn{
        border-radius: 6px;
        color: #fff;
        position: relative;
        padding: 9px 15px;
      }
      // .el-icon-check,.el-icon-delete{
      //   position: absolute;
      //   left: 20px;
      // }
      .new-btn{
         width: 85px;
         background: #3461FF;
      }
      .update-btn{
        // width: 190px;
        background: #3461FF;
      }
      .more-btn{
        // width: 140px;
        background: #3461FF;
      }
      .delete-btn{
        // width: 135px;
        background: #FC174D;
      }
    }
    .right {
      float: right;
      .import-btn,.export-btn,.share-btn{
        background: #EEEEEE;
        border: 1px solid #EEEEEE;
        border-radius: 6px;
        color:#666666;
      }
      .import-btn,.export-btn,.share-btn{
        width:80px;
      }
    }
    
  }
  .center {
    position: relative;
    background: #fff;
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.05);
    border-radius: 6px;
    // padding: 16px 28px;
    .refresh{
      background: #35b871;
      border: none;
      padding: 6px 8px;
      border-radius: 4px;
      color: #fff;
      float: right;
      margin-right: 10px;
    }
    ::v-deep .el-popover{
      position: absolute;
      right: 20px;
      width: 100px;
      height: 150px;
      cursor: pointer;
    }
    .center-title {
      padding: 15px;
      .number{
        font-size: 12px;
      }
      ::v-deep .el-input__inner{
        // width: 214px;
      }
      ::v-deep .el-select{
        // margin-right: 20px;
        // margin-left: 20px;
      }
    }
    .el-icon-more{
      float: right;
      height: 34px;
      line-height: 34px;
      cursor: pointer;
    }
    ::v-deep .el-table{
      .is-group tr:nth-child(2) .el-checkbox {
        display: none;
      }
      .is-group tr:last-child .el-checkbox{
        display: none;
      }
      .el-table__cell .cell{
        // width: 140px;
      }
      .date-cell{
        width: 350px;
        .el-date-editor{
          width: 140px;
        }
      }
      .el-icon-help,.el-icon-edit {
        font-size: 18px;
        width: 14px;
        cursor: pointer;
      }
      .el-icon-edit{
        margin: 0 17px;
      }
      .el-table__cell{
        text-align: center;
      }
    }
  }
}
::v-deep .search-icon {
  width: 31px;
  height: 31px;
  background: #edfff5;
  border-radius: 16px;
  color: rgba(53, 184, 113, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.cont-img{
  margin: 0 auto;
  width:100px;
  img{
    width:100%;
    height: 100;
  }
}
.image-dialog {
  border-radius: 6px;
  .title {
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 20px;
    height: 60px;
    line-height: 60px;
    background: hsl(226deg 29% 17%);
    padding: 0 21px;
    border-radius: 6px;
  }
  .items {
    padding: 0 21px;
    .items-title {
      font-size: 16px;
      color: #ffffff;
      border-bottom: 1px solid #ffffff;
    }
    
    .item-table {
      .item-table-header {
        display: flex;
        // justify-content: space-between;
        align-items: center;
        margin: 10px 0;
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        border-radius: 6px;
        padding: 0 12px;
        // background: #6F799A;
        div {
          width: 50%;
        }
      }
      .item-table-cont{
        height: 32px;
        line-height: 32px;
        padding: 0 12px;
        display: flex;
        // justify-content: space-between;
        align-items: center;
        margin: 10px 0;
        font-size: 16px;
        font-weight: 400;
        color: #fff;
        background: #6F799A;
        border-radius: 6px
      }
      // .item-table-list {
      //   height: 32px;
      //   line-height: 32px;
      //   background: #6f799a;
      //   border-radius: 6px;
      //   padding: 10px;

      //   display: flex;
      //   // justify-content: space-between;
      //   align-items: center;
      //   margin-bottom: 2px;

      //   span {
      //     width: 40%;
      //   }
      // }
    }
    .details {
      .title {
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        background: #282F45;
        height: 20px;
        line-height: 20px;
        padding: 0;
        margin: 20px 0;
      }
      .type{
        margin: 0 0px 18px 10px;
        color: #fff;
      }
      border-top: 1px solid rgba(54, 63, 92, 0.9);
      margin-top: 20px;
      .details-image {
        padding: 0 20px 20px;
        // width: 296px;
        height: 220px;
        width: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .items-types {
      .item-type {
        font-size: 16px;
        color: #ffffff;
        line-height: 24px;
      }
    }

    .btn-arrow{
      position: absolute;
      bottom: 0;
      left: 0;
      height: 40px;
      width: 100%;
      background: #5C647C;
      border-radius: 6px;
      text-align: center;
      line-height: 40px;
      i{
        color: hsl(147deg 58% 56%);
        font-size: 30px;
        cursor: pointer;
        line-height: 40px
      }
      .el-icon-arrow-right {
        margin-left: 30px;
      }
    }
  }
}
::v-deep .el-popper .popper__arrow::after {
      border-right-color: red !important;
    }
::v-deep .el-popover__reference{
      display: flex !important;
      align-items: center !important;
    }
::v-deep .el-table--border,.el-table--group{
  border: none !important;
}
::v-deep .el-textarea__inner {
  height: 34px;
}
</style>
