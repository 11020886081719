import router from "../axios";
import qs from 'qs'

import config from "../../config/index"
const { baseUrl } = config;

// 获取素材列表
function getImageList(data) {
  return router({
    url: baseUrl+"image/v1/getList",
    data:data,
    method: "POST",
  });
}

// 素材组 ---获取
function getImageGroupList(data) {
  return router({
    url: baseUrl+"imageGroup/v1/getList",
    data:data,
    method: "POST",
  });
}

// 素材组--创建
function createImageGroup(data) {
  return router({
    url: baseUrl+"imageGroup/v1/create",
    data:data,
    method: "POST",
  });
}
// 素材组---修改
function updateImageGroup(data) {
  return router({
    url: baseUrl+"imageGroup/v1/update",
    data:data,
    method: "POST",
  });
}
// 素材组---删除
function deleteImageGroup(data) {
  return router({
    url: baseUrl+"imageGroup/v1/delete",
    data:data,
    method: "POST",
  });
}

// 上传图片
function uploadImage(data) {
  return router({
    url: baseUrl+"file/v1/upload/image",
    data: data,
    method: "POST",
  });
}

// 素材 ---创建
function createImage(data) {
  return router({
    url: baseUrl+"image/v1/create",
    data:data,
    method: "POST",
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    timeout: 4000
  });
}
// 素材---批量创建
function batchCreateImage(data) {
  return router({
    url: baseUrl+"image/v1/batchCreate",
    data:data,
    method: "POST",
  });
}

// 素材 ---更新
function updateImage(data) {
  return router({
    url: baseUrl+"image/v1/update",
    data:data,
    method: "POST",
  });
}

// 素材--批量逻辑删除信息 
function imageBatchDelete(data) {
  return router({
    url: baseUrl+"image/v1/batchDelete",
    data:data,
    method: "POST",
  });
}

// 素材标签---创建
function createImageTag(data) {
  return router({
    url: baseUrl+"imageTag/v1/create",
    data:data,
    method: "POST",
  });
}
// 获取素材详情
function getImageInfor(data) {
  return router({
    url: baseUrl+"image/v1/get",
    data:data,
    method: "POST",
  });
}


export {
  getImageList,
  imageBatchDelete,
  getImageGroupList,
  createImageGroup,
  updateImageGroup,
  deleteImageGroup,
  uploadImage,
  createImageTag,
  createImage,
  batchCreateImage,
  updateImage,
  getImageInfor
}