<template>
  <div class="policy-box">
    <el-dialog
      :title="$t('itemsScenarios.ImportDialog.ImportPolicity')"
      width="1000px"
      class="import_dialog"
      :close-on-click-modal="false"
      :append-to-body="true"
      :visible.sync="showImportPolicy"
      :before-close="handelCancel"
      table-layout="fixed"
    >
      <el-form
        ref="tableInfo"
        :model="tableInfo" 
        :rules="rules"
        >
        <div class="form-first-column _flex">
          <!-- <el-form-item label="Date Format" class="_flex mr-right" prop="dateFormat">
            <el-input v-model="tableInfo.dateFormat" />
          </el-form-item> -->
          <el-form-item :label="$t('itemsScenarios.ImportDialog.StartingLine')" class="_flex mr-right" prop="startReadLine">
            <el-input v-model="tableInfo.startReadLine" type="number" size="small" />
          </el-form-item>
        </div>
        
        <div class="form-second-column _flex">
            <el-form-item :label="$t('itemsScenarios.ImportDialog.TargetStore')" class="_flex"  prop="targetStoreType" >
              <el-radio v-model="tableInfo.targetStoreType" :value="0" :label="0"></el-radio>
            </el-form-item>
            <el-form-item :label="$t('itemsScenarios.ImportDialog.File')"  class="_flex mr-left">
              <el-input class="csv-second" size="small" type="number" v-model="tableInfo.targetStore" :disabled="tableInfo.targetStoreType === 2 || (!tableInfo.targetStoreType && tableInfo.targetStoreType !== 0)"/>
              <span>{{$t('itemsScenarios.ImportDialog.Column')}}</span>
            </el-form-item>

            <el-form-item class="_flex" prop="" style="margin-left: 120px;">
              <el-radio v-model="tableInfo.targetStoreType" :value="2" :label="2"></el-radio>
            </el-form-item>
            <!-- <el-radio v-model="tableInfo.targetStoreType" :value="2" :label="2"></el-radio> -->

            <el-form-item :label="$t('itemsScenarios.ImportDialog.DesignatedStore')" class="_flex mr-left-more">
              <el-button class="chose-store" size="small"
                v-model="tableInfo.designatedStore" 
                :disabled="!tableInfo.targetStoreType" 
                @click="switchStore"><i class="el-icon-house" style="margin-right: 5px"/><span>{{ choseStoreText }}</span></el-button>
            </el-form-item>
        </div>
      </el-form>
      <div style="height: 500px">
        <el-scrollbar style="height:100%">
          <el-table
            border
            style="width:100%;margin-bottom: 20px;"
            :data="tableInfo.fieldMappingList"
            >
            <el-table-column
              :label="$t('itemsScenarios.ImportDialog.No')"
              prop="fieldSeq"
              width="50"
              align="center"
            >

            </el-table-column>
            <el-table-column
              :label="$t('itemsScenarios.ImportDialog.ImportFileFields')"
              prop="fieldName"
              width="140"
              align="center"
            >

            </el-table-column>
            <el-table-column
              :label="$t('itemsScenarios.ImportDialog.SceneFields')"
              width="200"
              align="center"
            >

              <template slot-scope="scope">
                <el-select size="small" v-model="scope.row.scenarioFieldKey" @change="getFieldType(scope.row, scope.row.scenarioFieldKey)" clearable>
                  <el-option
                    v-for="(itemField, indexField) in tableInfo.fieldList"
                    :key="indexField"
                    :label="itemField.translateTextName"
                    :value="itemField.fieldKey"
                  >
                  </el-option>
                </el-select>
              </template>

            </el-table-column>
            <el-table-column
              :label="$t('itemsScenarios.ImportDialog.Read')"
              props="isRead"
              width="100"
              align="center"
            >
              <template slot-scope="scope">
                <el-checkbox v-model="scope.row.isRead" />
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('itemsScenarios.ImportDialog.DefaultValue')"
              align="center"
            >
              <template slot-scope="scope">
                <el-input 
                  v-if="scope.row.type === 'text' || scope.row.type === 'number' || (scope.row.type === 'img' && !scope.row.imageType)" 
                  v-model="scope.row.defaultValue" 
                  size="small"
                />
                <el-select v-else-if="scope.row.type === 'checkbox'" v-model="scope.row.defaultValue" multiple size="small">
                  <el-option
                    v-for="(itemField, indexField) in tableInfo.checkboxList"
                    :key="indexField.id"
                    :label="itemField.name"
                    :value="itemField.id"
                  >
                  </el-option>
                </el-select>
                <el-date-picker
                  size="small"
                  style="width: 100%"
                  clearable
                  v-else-if="scope.row.type === 'date'"
                  v-model="scope.row.defaultValue"
                  type="date"
                  placement="bottom-start"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                />
                <el-input
                  size="small"
                  readonly
                  v-else-if="scope.row.type === 'img' && scope.row.imageType === 1"
                  v-model="scope.row.defaultValue"
                >
                  <template #append>
                    <div class="scenario-form-language" @click="showImgDialog(scope.row, 'imgLibrary')">{{$t('itemsScenarios.EditDialog.Browse')}}...</div>
                  </template>
                </el-input>
                
              </template>

            </el-table-column>
            <el-table-column
              :label="$t('itemsScenarios.ImportDialog.Thousandth')"
              props="thousandth"
              width="140"
              align="center"
            >
              <template slot-scope="scope">
                <el-select v-model="scope.row.thousandth" size="small">
                  <el-option
                    v-for="(itemField, indexField) in thousandthTypeList"
                    :key="indexField"
                    :label="itemField"
                    :value="itemField"
                  >
                  </el-option>
                </el-select>

              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" class="cancel-btn" @click="handelCancel">{{$t('button.Prior')}}</el-button>
        <el-button size="small" class="save-btn" @click="save('tableInfo')">{{$t('button.Save')}}</el-button>
      </span>
      <!-- <changeStoreDialog ref="changeStoreDialogRef"  :hasOwnerLevel="true" @changeStoreCallback="changeStoreCallback"></changeStoreDialog> -->
      <selectStore
        ref="selectStore"
        :multiple="true"
        @confirmSelect="changeStoreCallback"
      ></selectStore>
    </el-dialog>
    <!-- 选择图片 -->
    <imgSelect 
      @handleConfirm="selectImg"
      ref="selectImgRef"
    >
    </imgSelect>
  </div>
</template>
<script>
import { importForSecond } from '@/libs/api/scenario-item'
import selectStore from '@/components/select-store/index'
import imgSelect from '@/components/select-img/index.vue'

export default {
  components: {
    // changeStoreDialog,
    imgSelect,
    selectStore
  },
  data() {
    return {
      rules:{
        startReadLine: [{ required: true, message: this.$t('itemsScenarios.ImportDialog.StartingLineRequireTips'), trigger: 'blur' }],
        targetStoreType: [{ required: true, message: this.$t('itemsScenarios.ImportDialog.TargetStoreRequireTips'), trigger: 'blur' }],
        // dateFormat:[{ required: true, message: 'please chose  dateFormat', trigger: 'blur' }]
      },
      showStoreDialog: false,
      thousandthTypeList: [',', ';', '.', '/', '|'],
      tableList:[],
      tableInfo:{
        batchId: '',
        // dateFormat: 'yyyy-MM-dd HH:mm:ss',
        startReadLine: null,
        targetStoreType: null,
        targetStore: null,
        designatedStore: '',
        fieldMappingList:[],
        fieldList:[],
        checkboxList: []
      },
      imgLibraryRow: null // img 选择素材
    }
  },
  props: {
    showImportPolicy:{
      default: false,
      tyep: Boolean
    },
    scenarioId: {
      default: '',
      type: String
    },
    policyData: {
      type: Object,
      default:() => ({})
    }
  },
  computed: {
    choseStoreText() {
      return !this.tableInfo.designatedStore ? this.$t('itemsScenarios.ImportDialog.UnChoseStoreText') : this.$t('itemsScenarios.ImportDialog.ChosedStoreText')
    }
  },
  watch: {
    showImportPolicy: {
      async handler(val) {
        if (val) {
          console.log('policyData===',this.policyData)
          this.tableInfo.fieldMappingList = this.policyData?.fieldMappingList
          this.tableInfo.fieldList = this.policyData?.scenario?.fieldList
          this.tableInfo.batchId = this.policyData.batchId
          this.tableInfo.fieldMappingList.forEach(item =>{
            this.$set(item, 'type', 'text')
            this.$set(item, 'isRead', true)
            this.$set(item, 'scenarioFieldKey', '')
            this.$set(item, 'imageType', null)
            this.$set(item, 'defaultValue', '')
          })
          this.tableInfo.fieldList.forEach(item => {
            if (this.type === 'checkbox') {
              this.tableInfo.checkboxList = item.checkboxList
            }
          })
          console.log('tableList=======', this.tableList)
        }
      }
    }
  },
  methods:{
     // 选择图片--弹窗
    showImgDialog(row){
      this.imgLibraryRow = row
      this.$refs.selectImgRef.open()
    },
    // 选择图片
    selectImg(obj){
      console.log('选择的图片',obj)
      this.tableInfo.fieldMappingList[this.imgLibraryRow.fieldSeq - 1]['type'] = 'img'
      this.tableInfo.fieldMappingList[this.imgLibraryRow.fieldSeq - 1]['imageType'] = 1
      this.tableInfo.fieldMappingList[this.imgLibraryRow.fieldSeq - 1]['defaultValue'] = obj.imageUrl
    },
    switchStore () {
      // this.$refs.selectStore.openchangeStoreDialog(this.tableInfo.targetStore);
      this.$refs.selectStore.open(this.tableInfo.designatedStore);
    },
    // changeStoreCallback (ids) {
    //   this.tableInfo.designatedStore = ids;
    //   console.log('this.tableInfo.designatedStore.',this.tableInfo.designatedStore)
    // },
    changeStoreCallback(arr) {
      console.log(arr);
      let ids = arr.map((item) => item.storeId).join(",");
      this.tableInfo.designatedStore = ids;
      console.log('this.tableInfo.designatedStore.',this.tableInfo.designatedStore)
    },
    // 监听选中的fieldtype 显示defaultvalue
    getFieldType(row, scenarioFieldKey) {
      console.log('scenarioFieldKey=====', scenarioFieldKey)
      this.tableInfo.fieldMappingList[row.fieldSeq - 1]['defaultValue'] = ''
      let currentType = 'text'
      let currentimageType
      if (scenarioFieldKey) {
        currentType = (this.tableInfo.fieldList.find(item => item.fieldKey === scenarioFieldKey))['type']
        currentimageType = (this.tableInfo.fieldList.find(item => item.fieldKey === scenarioFieldKey))['imageType']
      } else {
        currentType = 'text'
        currentimageType = null
      }
      console.log('currentType=====', currentType)
      console.log('currentimageType=====', currentimageType)
      this.tableInfo.fieldMappingList[row.fieldSeq - 1]['type'] = currentType
      this.tableInfo.fieldMappingList[row.fieldSeq - 1]['imageType'] = currentimageType
    },
    // 取消 - 上一步
    handelCancel() {
      this.$emit('handleImportPolicy')
      this.$refs['tableInfo'].resetFields();
      this.tableInfo.targetStoreType = null
      this.tableInfo.targetStore = null
      this.tableInfo.designatedStore = ''
    },
    // 导入 第二部 保存
   save() {
      this.$refs['tableInfo'].validate(async valid => {
        if (valid) {
          if (this.tableInfo.targetStoreType === 0 && this.tableInfo.targetStore > this.tableInfo.fieldMappingList.length) {
            this.$message.error(this.$t('itemsScenarios.ImportDialog.CSVSecondExceedTips'))
            return
          } else if( this.tableInfo.targetStoreType === 0 && this.tableInfo.targetStore < 1){
            this.$message.error(this.$t('itemsScenarios.ImportDialog.CSVSecondLimitTips'))
            return
          }
          if (this.tableInfo.startReadLine < 1){
            this.$message.error(this.$t('itemsScenarios.ImportDialog.StartingLineLimitTips'))
            return
          }
          // if (this.tableInfo.startReadLine > this.tableInfo.fieldMappingList.length) {
          //   this.$message.error('startReadLine exceed limit')
          //   return
          // }
          if (this.tableInfo.targetStoreType === 2 && !this.tableInfo.designatedStore){
            this.$message.error(this.$t('itemsScenarios.ImportDialog.TargetStoreRequireTips'))
            return
          }
          const arr = []
          this.tableInfo.fieldMappingList.forEach(item =>{
            item = {
              isRead: item.isRead ? 1 : 0,
              defaultValue: item.defaultValue,
              scenarioFieldKey: item.scenarioFieldKey,
              thousandth: item.thousandth,
              fieldSeq: item.fieldSeq,
              fieldName: item.fieldName
            }
            if (item.scenarioFieldKey !=='') {
              arr.push(item)
            }
          })
          if (arr.length === 0) {
            this.$message.error(this.$t('itemsScenarios.ImportDialog.SceneFieldRequireTips'))
            return
          }
          if (arr.length > this.tableInfo.fieldList.length) {
            this.$message.error(this.$t('itemsScenarios.ImportDialog.SceneFieldLimitTips'))
            return
          }
          const params = {
            batchId: this.tableInfo.batchId,
            startReadLine: this.tableInfo.startReadLine,
            // dateFormat: this.tableInfo.dateFormat,
            targetStoreType: this.tableInfo.targetStoreType,
            targetStore: this.tableInfo.targetStoreType === 0 ? this.tableInfo.targetStore : this.tableInfo.designatedStore,
            fieldMappingList: arr
          }
          console.log('params======', params)
          const res = await importForSecond(params)
          if (res.status === 200 && res?.data?.code === 1) {
            this.$message.success(this.$t('itemsScenarios.ImportDialog.ImportSuccess'));
            this.$emit('handleImportPolicy', 'confirm')
          } else {
            this.$message.error(res?.data?.message);
          }
        }
      })
    }

  }
}
</script>
<style scoped lang="scss">
._flex{
  display: flex;
}
.mr-right{
  margin-right: 30px;
}
.mr-left{
  margin-left: 30px;
}
.mr-left-more{
  margin-left: 20px;
}
 .policy-box ::v-deep {
   .el-form-item__content{
     display: flex !important;
     margin-right: 20px;
   }
   .el-form-item__label{
     line-height: 34px;
   }
 } 
::v-deep .el-dialog{
  height: 800px;
}
::v-deep .el-radio__label{
  display: none !important;
  margin-right: 20px;
}
.csv-second::v-deep.el-input{
  width:auto !important;
  .el-input__inner {
    width: 100px;
    margin-right: 12px;
  }
}
.form-second-column{
  ::v-deep .el-form-item__error{
    width: 500px;
    margin-left: -75px;
  }
}
.dialog-footer ::v-deep {
  text-align: center;
  .el-button{
    width: 130px;
    height: 40px;
    box-shadow: 0px 1px 15px 0px rgba(53,184,113,0.55);
    border-radius: 6px;
    color: #fff;
    background: #35b871 !important;
  }
  // .el-button.cancel-btn {
  //   background: #d4d4d4 !important;
  //   box-shadow: 0px 1px 15px 0px rgba(99, 99, 99, 0.23);
  //   color: #626262;
  // }
  // .el-button.save-btn {
  //   background: #35b871 !important;
  //   box-shadow: 0px 1px 15px 0px rgba(53, 184, 113, 0.55);
  //   color: #ffffff;
  // }
}
</style>
