<template>
  <div class="select-store-component">
    <el-dialog
      :title="$t('header.Switch Store')"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :before-close="handleClose" 
      custom-class="dialog-select-store"
      width="600px"
      append-to-body
    >
      <div class="select-store-bd">
        <el-input icon="el-icon-search" :placeholder="$t('header.Input Store Name')" v-model="searchStoreName" clearable size="small" />
        <div class="box" >
          <el-scrollbar style="height:100%">
            <template v-if="multiple">
              <!-- 多选 -->
              <el-tree
                ref="tree"
                :data="nextStoreList"
                :props="defaultProps"
                node-key="storeId"
                default-expand-all
                :default-checked-keys="selectedStoreIds"
                :filter-node-method="filterNode"
                :show-checkbox="multiple"
                :check-strictly="true"
              >
              </el-tree>
            </template>
            <template v-else>
              <!-- 单选 -->
              <el-tree
                ref="tree"
                :data="nextStoreList"
                :props="defaultProps"
                node-key="storeId"
                default-expand-all
                :filter-node-method="filterNode"
              >
                <div slot-scope="{node,data}" style="width:100%">
                  <el-radio
                    v-model="chooseStoreId"
                    :label="data.storeId"
                    @change="change(data)"
                  >
                    {{ node.label }}
                  </el-radio>
                </div>
              </el-tree>
            </template>
            
          </el-scrollbar>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleClose('cancel')" style="background: #D4D4D4;">{{$t('button.Cancel')}}</el-button>
        <el-button size="small" class="base-color-btn" type="success" @click="handleConfirm('confirm')">{{$t('button.Confirm')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { switchPage} from '@/libs/api/ap-config'
  export default {
    name:'selectStore',
    components: {
    },
    props: {
      hasOwnerLevel: {
        type: Boolean,
        default: true,
      },
      multiple: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        dialogVisible:false,
        searchStoreName:'',
        nextStoreList:[], //列表数据源
        defaultProps: {
          label:'storeName',
          children: 'nextStoreList'
        },
        selectedStoreIds:[], // 默认选中的key
        chooseStoreId: '', // 最终选中的storeid信息
        chooseStoreInfor: {} // 最终选中的门店具体信息
      }
    },
    created(){
      
    },
    watch: {
      searchStoreName(val) {
        this.$refs.tree.filter(val)
      }
    },
    methods: {
      open(selectedStoreIds){
        this.dialogVisible = true
        // this.chooseStoreId = localStorage.getItem('storeId') || ''
        this.getData()
        if(selectedStoreIds){
          console.log('selectedStoreIds',selectedStoreIds)
          if(this.multiple){
            // 多选
            this.selectedStoreIds = selectedStoreIds.split(',')
          } else {
            // 单选
            this.chooseStoreId = selectedStoreIds
          }
        } else {
          this.selectedStoreIds = []
          this.chooseStoreId = ''
        }
      },
      // 获取数据
      async getData(){
        let userData = JSON.parse(localStorage.getItem('userData'))
        // let storeId = localStorage.getItem('storeId')
        const params = {
          storeId: userData.storeId,
          name: this.searchStoreName
        }
        try {
          const res = await switchPage(params)
          let response = res.data
          if(response.state){
            const data = res.data?.data
            if(this.hasOwnerLevel){
              let arr =[data]
              this.nextStoreList = arr
            } else {
              this.nextStoreList = data?.nextStoreList
            }
          } else{
            this.$message.error(response.message);
          }
        } catch (error) {
          this.$message.error(error.message);
        }
      },
      // 取消选择
      handleClose() {
        this.dialogVisible = false
        this.chooseStoreId = ''
        this.chooseStoreInfor ={}
      },
      // 过滤请求数据
      filterNode(value, data) {
        console.log('storeName', value, data)
        if (!value) return true;
        return data.storeName.indexOf(value) !== -1;
      },
      // 选择树组件的节点
      change(val) {
        this.chooseStoreInfor = val
      },
      // 点击确定
      handleConfirm(){
        let { chooseStoreId, chooseStoreInfor} = this
        this.dialogVisible = false
        if(this.multiple){
          // 多选
          let arr = this.$refs.tree.getCheckedNodes()
          this.$emit('confirmSelect', arr)
          console.log(arr)
        } else {
          // 单选
          this.$emit('confirmSelect', chooseStoreId, chooseStoreInfor)
        }
        
      }
    }
  }
</script>
<style lang="scss">
  .dialog-select-store {
    // height: 580px;
    margin-top: 0!important;
    margin-bottom: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    .el-dialog__body{
      height: 400px;
      padding: 20px;
      box-sizing: border-box;
    }
    .select-store-bd{
      margin: 0 auto;
      width:80%;
      ::v-deep .el-radio{
        margin-top: 5px;
        padding: 10px 0;
        width: 100%;
      }
    }
    .box{
      height: 330px;
      margin-top: 20px;
    }
    .el-table thead{
      display: none;
    }
    .el-button{
      width: 110px;
    }
    .el-tree-node__content{
      height: 45px;
    }
    .el-table td.el-table__cell{
      border: none;
      width: 100%;
    }
    .el-table td.el-table__cell > .cell{
      justify-content: flex-start;
    }
    .el-table__row{
      display: flex;
    }
    // ::v-deep .el-radio{
    //   margin-top: 5px;
    //   padding: 20px 0;
    //   width: 100%;
    // }
    ::v-deep .el-table{
      .el-table__header{
        width: 100% !important;
      }
      th.el-table__cell>.cell{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
      }
      td.el-table__cell>.cell{
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .is-group tr:nth-child(2) .el-checkbox {
        display: none;
      }
    }

    // 重置样式
    .el-scrollbar__wrap{
      overflow-x: hidden;
    }
    .el-dialog__footer{
      padding: 20px 0;
    }
    .el-radio {
      padding: 14px 0;
      margin-bottom: 0;
    }
  }
</style>