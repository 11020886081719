<template>
  <div class="view-dialog">
    <el-dialog
      :title="viewDialogTitle"
      :visible.sync="showViewDialog"
      :before-close="handleClose"
      :close-on-click-modal="false"
      width="600px"
      >
      <div style="height: 600px">
        <el-scrollbar style="height:100%">
          <el-form 
            class="cont"
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
            >
            <el-form-item class="name" prop="viewName" :label="$t('itemsScenarios.ViewDialog.ViewName')">
                <!-- <span>{{$t('itemsScenarios.ViewDialog.ViewName')}}</span> -->
                <el-input v-model="ruleForm.viewName" size="small"></el-input>
                <el-checkbox v-model="defaultType">{{$t('itemsScenarios.ViewDialog.Default')}}</el-checkbox>
            </el-form-item>

            <el-form-item class="visible" prop="openType" :label="$t('itemsScenarios.ViewDialog.VisibleRange')">
              <!-- <span class="title">{{$t('itemsScenarios.ViewDialog.VisibleRange')}}</span> -->
              <span >
                <el-radio :disabled="item.disabled" v-for="(item, index ) in radioList" :key="index" v-model="ruleForm.openType" :label="item.id" @change="changeRadio">{{item.title}}</el-radio>
              </span>
            </el-form-item>

            <el-form-item class="filter">
              <span class="title">{{$t('itemsScenarios.ViewDialog.Filter')}}</span>
              <div class="cont" v-for="(showItem, indexItem) in viewQueryCondition" :key="indexItem">

                <el-select size="small" ref="select-left" class="select-left" clearable v-model="showItem.columnName" @change="changeFieldLeft(indexItem)" :select-left="showItem">
                  <el-option
                    v-for="(item) in showItem.staticFields"
                    :key="item.columnName"
                    :label="item.translateTextName"
                    :value="item.columnName">
                  </el-option>
                </el-select>

                <el-select size="small" ref="select-center" class="select-center" clearable v-model="showItem.condtion" @change="changeCharacter(indexItem)" :select-center="showItem">
                  <el-option
                    v-for="item in showItem.characterList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>

                <el-input 
                  class="select-right"
                  size="small"
                  clearable
                  :type="showItem.fieldType === 'number' ? 'number':'text'"
                  :disabled="showItem.canotEdit"
                  v-if="!showItem.fieldType || showItem.fieldType === 'text' || showItem.fieldType === 'number' || showItem.fieldType === 'img' || showItem.fieldType === 'url'" 
                  v-model="showItem.fieldValue" 
                  @input="getFilterValue(showItem.fieldValue, indexItem)"
                />
                <el-date-picker
                  size="small"
                  class="select-right"
                  v-else-if="showItem.fieldType === 'date'"
                  v-model="showItem.fieldValue"
                  @change="getFilterValue(showItem.fieldValue, indexItem)"
                  type="date"
                  placement="bottom-start"
                  value-format="yyyy-MM-dd"
                  >
                </el-date-picker>

                  <!-- <el-select v-else class="select-right" v-model="showItem.checkboxFieldValue" @change="getFilterValue(showItem.checkboxFieldValue, indexItem)"> -->
                <el-select v-else-if="showItem.fieldType === 'checkbox'" size="small" class="select-right" v-model="showItem.fieldValue" @change="getFilterValue(showItem.fieldValue, indexItem)">
                  <el-option
                    v-for="item in showItem.checkBoxList"
                    :key="item.displayOrder"
                    :label="item.text"
                    :value="item.text">
                  </el-option>
                </el-select>

                <el-button size="small" type="primary" v-if="indexItem === 0" @click="addSenario">{{$t('itemsScenarios.ViewDialog.Add')}}</el-button>
                <i v-else class="delect el-icon-close" @click="delectSenario(indexItem)"/>
              </div>
              
            </el-form-item>

            <el-form-item class="select-column" prop="checkedStoreType" :label="$t('itemsScenarios.ViewDialog.checkedStoreType')">
              <!-- <span>Select Column</span> -->
              <!-- <el-scrollbar style="height:calc(100% - 50px)" > -->
                <el-checkbox-group v-model="ruleForm.checkedStoreType" @change="handleCheckedStoreType">
                  <el-checkbox v-for="(itemStore, indexStore) in storeList" :key="indexStore" :label="itemStore">{{itemStore.translateTextName}}</el-checkbox>
                </el-checkbox-group>
              <!-- </el-scrollbar> -->
            </el-form-item>
        
          </el-form>
        </el-scrollbar>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-btn" @click="handleClose">{{$t('button.Cancel')}}</el-button>
        <el-button class="save-btn" @click="save">{{$t('button.Confirm')}}</el-button>
      </span>
    </el-dialog>
    <confirm-dialog
      :dialogVisible="delectSenarioVisible"
      @handleClose="handleCloseDelectSenario"
      :confirmObj="confirmObj"
    >
    </confirm-dialog>
  </div>
</template>
<script>
import { getScenario,createView,getViewDetail, updateView } from '@/libs/api/scenario-item'
import ConfirmDialog from '@/components/confirm-dialog/index.vue'
export default {
  data() {
    return {
      viewDialogTitle: '',
      ruleForm:{
        viewName: '',
        openType: '',
        checkedStoreType: []
      },
      defaultType: false,
      radioList:[{id: '2', title: this.$t('itemsScenarios.ViewDialog.ThisUser'),disabled: false},{id: '0', title: this.$t('itemsScenarios.ViewDialog.ThisStore'),disabled: false},{id: '1', title: this.$t('itemsScenarios.ViewDialog.AllStore'),disabled: false}],
      characterTypeList:{ // // filter 第二项选择的下拉框
        'text': [{id: 'in',name: this.$t('Contain')},{id: 'eq', name: this.$t('Equal')},{id: 'nl', name: this.$t('IsEmpty')},{id: 'nn', name: this.$t('NotEmpty')}],
        'number': [{id: 'gt', name:'>'},{id: 'ge',name:'>='},{id: 'lt',name:'<'},{id: 'le',name:'<='},{id: 'eq',name:'='},{id: 'ne', name:'!='}],
        'date': [{id: 'gt', name:'>'},{id: 'ge',name:'>='},{id: 'lt',name:'<'},{id: 'le',name:'<='},{id: 'eq',name:'='}],
        'checkbox':[{id: 'in', name: this.$t('Contain')}],
        'img':[{id: 'in', name: this.$t('Contain')}],
      },
      characterId:'',   // filter 第二项选择的character
      fieldLeftId: '',  // filter 第一项选择的id
      fieldRightValue: '', // filter 第三项输入的值
      viewQueryCondition:[], //  filter 填入的内容绑定的数组
      viewListField: [], //  store 选择的内容绑定的数组
      storeList: [], // store选择框
      resDefaultData: {
        staticFields: [],
      },
      leftIndex: 0,
      centerIndex: 0,
      rules:{
        viewName: [{ required: true, message: this.$t('itemsScenarios.ViewDialog.ViewNameRequireTips'), trigger: ['blur', 'change']}],
        openType: [{ required: true, message: this.$t('itemsScenarios.ViewDialog.VisibleRangeRequireTips'), trigger: ['blur', 'change']}],
        checkedStoreType: [{ required: true, message: this.$t('itemsScenarios.ViewDialog.SelectColumnRequireTips'), trigger: ['blur', 'change']}],
      },
      confirmObj: {
        type: this.$t('ConfirmDialog.Warning'),
        cancelText: this.$t('ConfirmDialog.ThinkAgain'),
        confirmText: this.$t('ConfirmDialog.Yes'),
        content: this.$t('ConfirmDialog.DeleteTips'),
      },
      delectSenarioVisible: false,// 确认删除弹框
      delectIndex: null // 删除filter   index
    }
  },
  components:{
    ConfirmDialog
  },
  props: {
    scenarioId: {
      default: '',
      type: String,
    },
    scenarioStatus: {
      default: '',
      type: String,
    },
    showViewDialog: {
      default: false,
      type: Boolean,
    },
    viewId: {
      default: '',
      type: String,
    },
  },
  computed:{
   
  },
  watch:{
    'fieldLeftId': {
      handler(val) {
        if (this.viewQueryCondition[this.leftIndex]){
          this.viewQueryCondition[this.leftIndex]['fieldValue'] = ''
          this.viewQueryCondition[this.leftIndex]['condtion'] = ''
          // this.viewQueryCondition[this.leftIndex]['checkboxFieldValue'] = []
        }
      },
      immediate: true
    },
    'characterId': {
      handler(val) {
        console.log('characterId=====', val)
        if (this.viewQueryCondition[this.centerIndex]){
          if (val === 'nl' || val === 'nn') {
            this.$set(this.viewQueryCondition[this.centerIndex], 'canotEdit', true)
          } else {
            this.$set(this.viewQueryCondition[this.centerIndex], 'canotEdit', false)
          }
          this.viewQueryCondition[this.centerIndex]['fieldValue'] = ''
        }
      },
      deep: true,
      immediate: true
    },
    'scenarioStatus': {
      handler(val){
        console.log('scenarioStatus====', val)
        if (val === 'new') {
          this.viewDialogTitle = this.$t('itemsScenarios.New')
          this.getScenarioInfo()
        } else if (val === 'edit') {
          this.viewDialogTitle = this.$t('itemsScenarios.Edit')
          this.getEditDetail()
        } else if (val === 'copy'){
          this.viewDialogTitle = this.$t('itemsScenarios.Copy')
          this.getEditDetail()
        }
      },
    },
    'defaultType': {
      handler(val) {
        console.log('defaultType======', val)
        if (val) {
          this.radioList[1]['disabled'] = true
          this.radioList[2]['disabled'] = true
          this.ruleForm['openType'] = ''
        } else {
          this.radioList.forEach(item => {
            item['disabled'] = false
          })
        }
      },
      // immediate: true
    }
  },
  mounted() {
  },
  methods: {
    // 编辑 / 复制操作  获取视图信息 1575772566958907392 1577497360746184704 '1577577167366410240'
    async getEditDetail() {  
      const params = {id: this.viewId, scenarioId: this.scenarioId}
      const res = await getViewDetail(params)
      if (res.status === 200 && res?.data?.code === 1) {
        const { data } = res.data
        const scenarioData = data.scenario
        this.ruleForm.viewName = this.scenarioStatus === 'copy' ? `${data.viewName}` + '(' + this.$t('itemsScenarios.Copy') + ')' : data.viewName
        this.defaultType = data.defaultType === -1 ? true : false
        this.ruleForm.openType = data.openType?.toString()
        this.resDefaultData.staticFields = scenarioData.fieldList.concat(scenarioData.staticFields)
        this.storeList = scenarioData.fieldList.concat(scenarioData.staticFields)
        this.viewQueryCondition = data.viewQueryCondition
        this.viewListField = data.viewListField
        this.ruleForm.checkedStoreType = []
        // 新增
        console.log('this.viewQueryCondition', this.viewQueryCondition)
        this.viewQueryCondition.forEach(t => {
          t.staticFields = this.resDefaultData.staticFields
          t.characterList = this.characterTypeList[t.fieldType]
          t.staticFields.forEach(item => {
            if (t.fieldType === item.type && t.fieldType === 'checkbox' && t.columnName === item.columnName) {
               t['checkBoxList'] = item.checkBoxList
              //  this.$set(t, 'checkboxFieldValue', [])
              //  t.checkboxFieldValue = t.fieldValue && t.fieldValue.split(',')
            }
          })
        })
        console.log('22222222this.viewQueryCondition', this.viewQueryCondition)
        console.log('data.viewListField', data.viewListField)
        console.log('this.storeList', this.storeList)
        data.viewListField.forEach(item => {
          this.storeList.forEach(t => {
            if (item.columnName === t.columnName && item.fieldType === t.type) {
              this.ruleForm.checkedStoreType.push(t)
            }
          })
        })
        console.log('this.ruleForm.checkedStoreType', this.ruleForm.checkedStoreType)
        console.log('编辑this.viewQueryCondition=======',this.viewQueryCondition)
      } else {
        this.$message({
          message: res?.data?.message,
          type: 'warning'
        });
      }
    },
    // 新建获取场景详情信息
    async getScenarioInfo() {
      const params = {scenarioId: this.scenarioId}
      const res = await getScenario(params)
      if (res.status === 200 && res?.data?.code === 1){
        const { data } = res.data
        this.viewQueryCondition = [
          {
            fieldId: '',
            fieldType: '',
            fieldName: '',
            columnName: '',
            condtion:'',
            fieldValue: '',
            // checkboxFieldValue: [],
            staticFields:[],
            characterList: [],
            checkBoxList:[]
          }
        ]
        this.ruleForm.viewName = ''
        this.ruleForm.openType = null
        this.defaultType = false
        this.resDefaultData.staticFields = data.fieldList.concat(data.staticFields)
        this.viewQueryCondition[0].staticFields = data.fieldList.concat(data.staticFields)
        this.storeList = data.fieldList.concat(data.staticFields)
      }
    },
    // filter 第一项选择
    changeFieldLeft(val) {
      this.leftIndex = val
      this.fieldLeftId = this.$refs['select-left'][val].$attrs['select-left']['columnName']
      console.log('this.fieldLeftId==========', this.fieldLeftId)
      this.viewQueryCondition.forEach(t => {
        t.staticFields.forEach(item =>{
          if (this.fieldLeftId === item.columnName) {
            console.log('itme=========', item)
            this.viewQueryCondition[val]['fieldId'] = item.fieldId
            this.viewQueryCondition[val]['fieldType'] = item.type
            this.viewQueryCondition[val]['fieldName'] = item.columnName
            this.viewQueryCondition[val]['columnName'] = item.columnName
            this.viewQueryCondition[val]['characterList'] = this.characterTypeList[item.type]
            if (item.type === 'checkbox') {
              this.viewQueryCondition[val]['checkBoxList'] = item.checkBoxList
            }
          }
        })
      })
      console.log('this.this.viewQueryCondition',this.viewQueryCondition)
    },
    // filter 第二项选择 符号选择
    changeCharacter(val) {
      console.log('this.viewQueryCondition',this.viewQueryCondition)
      console.log('this.$refsselect-cente======',this.$refs['select-center'][val])
      this.centerIndex = val
      this.characterId = this.$refs['select-center'][val].$attrs['select-center']['condtion']
      console.log('this.characterId======',this.characterId)

      this.viewQueryCondition.forEach(t => {
        t.characterList.forEach(item => {
          if (item.name === this.characterId) {
            this.viewQueryCondition[val]['condtion'] = item.id
          }
        })
      })
    },
    // filter 第三项选择 输入的值
    getFilterValue(val, index){
      console.log('11111getFilterValue',val, index)
      this.viewQueryCondition[index].fieldValue = val
      // this.viewQueryCondition[index].checkboxFieldValue = val
      console.log('2222getFilterValue',this.viewQueryCondition)
    },
    // 添加场景
    addSenario() {
      if (this.viewQueryCondition.length === 5) {
        this.$message.warning(this.$t('itemsScenarios.ViewDialog.AddLimitTips'))
        return
      }
      this.viewQueryCondition.push(
        {
          fieldId: '',
          fieldType: '',
          fieldName: '',
          columnName: '',
          condtion:'',
          fieldValue: '',
          // checkboxFieldValue: [],
          staticFields: this.resDefaultData.staticFields,
          characterList: []
        }
      )
      console.log('this.viewQueryCondition', this.viewQueryCondition)
    },
    // 删除场景
    async handleCloseDelectSenario(val) {
      if (val === 'confirm') {
        this.viewQueryCondition.splice(this.delectIndex, 1)
        this.$message.success(this.$t('itemsScenarios.ViewDialog.DeleteSuccess'))
      }
      this.delectSenarioVisible = false

    },
    delectSenario(index) {
      // this.viewQueryCondition.splice(index, 1)
      this.delectSenarioVisible = true
      this.delectIndex = index
    },
    // 选择store select
    handleCheckedStoreType(val) {
      console.log('handleCheckedStoreType------------', val)
      this.viewListField = []
      val.forEach(item => {
        this.viewListField.push({
          fieldId: item.fieldId,
          fieldType: item.type,
          fieldName: item.columnName
        })
      })

    },
    save() {
      console.log('this.viewListField======', this.viewListField)
      // 新建 / 编辑 /复制
      let conditionArr = []
      this.viewQueryCondition.forEach(item => {
        conditionArr.push({
              fieldId: item.fieldId,
              fieldType: item.fieldType,
              fieldName: item.fieldName,
              condtion: item.condtion,
              // fieldValue:  item.fieldType === "checkbox" ? item.checkboxFieldValue.join() : item.fieldValue
              fieldValue:  item.fieldValue
            })
        // this.viewListField.forEach(t => {
        //   if (item.fieldId === t.fieldId && item.fieldType === t.fieldType && item.fieldName === t.fieldName) {
        //     conditionArr.push({
        //       fieldId: item.fieldId,
        //       fieldType: item.fieldType,
        //       fieldName: item.fieldName,
        //       condtion: item.condtion,
        //       // fieldValue:  item.fieldType === "checkbox" ? item.checkboxFieldValue.join() : item.fieldValue
        //       fieldValue:  item.fieldValue
        //     })
        //   }
        // })
      })
      this.$refs['ruleForm'].validate(async (valid) => {
        if(valid) {
           const params = {
            viewType: 0,
            viewName: this.ruleForm.viewName,
            defaultType: this.defaultType ? -1 : 1,
            openType: this.ruleForm.openType,
            scenarioId: this.scenarioId,
            viewQueryCondition: conditionArr,
            viewListField: this.viewListField
          }
          if (this.scenarioStatus === 'edit') params.id = this.viewId
          console.log('params的值+++++++++++==', params)
          let res
          if (this.scenarioStatus === 'new' || this.scenarioStatus === 'copy') {
            res = await createView(params)
          } else {
            res = await updateView(params)
          }
          if (res.status === 200 && res?.data?.code === 1){
            this.$message.success(this.$t('itemsScenarios.ViewDialog.SaveSuccess'))
          } else {
            this.$message.warning(res?.data?.message)
          }
          this.$emit('handleViewClose', 'confirm')
          this.$refs['ruleForm'].resetFields();
        }
      })
    },
    handleClose() {
      this.$emit('handleViewClose')
      this.$refs['ruleForm'].resetFields();
    },
    changeRadio(val) {
      console.log('val------------', val)
    },
  }
}
</script>
<style lang="scss" scoped>
.view-dialog{
  span {
    line-height: 1;
  }
  .cont{
    ::v-deep .el-form-item__label{
      //display: block !important;
      text-align: left !important;
      float: none !important;
      line-height: 1;
      margin-bottom: 20px;
    }
    .name{
      // width: 557px;
      // height: 120px;
      background: #F9F9F9;
      border-radius: 6px;
      padding: 20px;
      margin-bottom: 10px;
      span{
        display: block;
        margin-bottom: 20px;
      }
      ::v-deep .el-input{
        margin-right: 10px;
        width: 360px;
        height: 40px;
        margin-right: 10px;
      }
      ::v-deep .el-form-item__error{
        top: 100%;
      }
    }
    .visible{
      // height: 120px;
      background: #F9F9F9;
      border-radius: 6px;
      padding: 20px;
      margin-bottom: 10px;
      span{
        display: block;
      }
      .title{
         margin-bottom: 20px;
      }
      ::v-deep .el-form-item__error{
        top: 100%;
      }
    }
    .filter{
      .cont{
        margin-bottom: 10px;
      }
      background: #F9F9F9;
      border-radius: 6px;
      padding:20px;
      margin-bottom: 20px;
      span{
        display: block;
      }
      .title{
        margin-bottom: 20px;
      }
      .el-select.select-left{
        width: 140px;
        margin-right: 5px;
      }
      .el-select.select-center{
        width: 90px;
        margin-right: 5px;
      }
      .select-right{
        width: 140px;
        margin-right: 5px;
      }
      .text-input{
        width: 140px;
      }
      .delect.el-icon-close{
        font-size:30px;
        vertical-align: middle;
        cursor: pointer;
        margin-left: 20px;
      }
      .el-button{
        // margin-left: 10px;
      }

    }
    .select-column{
      background: #F9F9F9;
      border-radius: 6px;
      // padding: 10px 24px 20px 24px;
      padding: 20px;
      margin-bottom: 10px;
      span{
        display: block;
        margin-bottom: 20px;
      }
      ::v-deep .el-form-item__content{
        // height: 220px;
        line-height: 20px;
      }
      ::v-deep .el-checkbox{
        display: block;
        line-height: 30px;
      }
      ::v-deep .el-form-item__error{
        top: -15px;
      }
    }
  }
  ::v-deep .el-dialog__body{
    padding:24px 22px;
    border-radius: 6px;
  }
}
.dialog-footer ::v-deep {
  text-align: center;
  .el-button.cancel-btn {
    background: #d4d4d4 !important;
    box-shadow: 0px 1px 15px 0px rgba(99, 99, 99, 0.23);
    color: #626262;
  }
  .el-button.save-btn {
    background: #35b871 !important;
    box-shadow: 0px 1px 15px 0px rgba(53, 184, 113, 0.55);
    color: #ffffff;
  }
}
</style>