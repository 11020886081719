<template>
  <el-dialog
    :title="$t('importDialog.Import')"
    width="500px"
    class="import_dialog"
    :close-on-click-modal="false"
    :append-to-body="true"
    :visible.sync="isVisible"
    :before-close="handelCancel"
  >
    <div>
      <div class="flex_ flex-column-center flex-row-center">
        <el-radio
          v-for="(item, i) in importTypeList"
          :key="i"
          v-model="importType_"
          :label="item.label"
          @change="changeType"
          >{{ item.name }}</el-radio
        >
      </div>
      <div class="flex_ upload-item">
        <span class="label">{{ $t("importDialog.File") }}</span>
        <el-upload
          class="upload-demo"
          action="#"
          :accept="currentFileType"
          :before-upload="beforeUpload"
          :http-request="uploadHttpRequest"
          :show-file-list="false"
        >
          <el-button size="small" type="primary" >
            <span class="select-file-text" v-if="!filesObj.fileName"> {{ $t("importDialog.SelecttoFile") }} </span>
            <span class="select-file-text" v-else>
              {{ filesObj.fileName }}
            </span>
            <i class="el-icon-plus el-icon--right"></i>
          </el-button>
        </el-upload>
      </div>
    </div>
    <div v-if="source && importType_ === 'csv'" class="flex_ csv_character">
      <span class="label" style="width: 100px">{{ $t("importDialog.Separator") }}</span>
      <!-- <el-input type="text" /> -->
      <el-select v-model="separator" size="small" @change="getSepVal(separator)">
        <el-option
          v-for="(itemField, indexField) in thousandthTypeList"
          :key="indexField"
          :label="itemField"
          :value="itemField"
        >
        </el-option>
      </el-select>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button class="cancel-btn btn" size="small" @click="handelCancel"
        >{{ $t("importDialog.Cancel") }}</el-button
      >

      <el-button @click="handelSubmit" class="save-btn btn" size="small"
        >{{ $t("importDialog.Submit") }}</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "ImportSingleFile",
  props: {
    source: {
      type: String,
      require: true,
      default: "",
    },
    isVisible: {
      type: Boolean,
      require: true,
      default: false,
    },
    importTypeList: {
      type: Array,
      require: true,
      default: () => [],
    },
    importType: {
      type: String,
      require: true,
      default: "excel",
    },
    acceptFileType: {
      type: String,
      require: true,
      default: ".xls,.xlsx",
    },
    scenarioId: {
      default: "",
      tyep: String,
    },
  },

  data() {
    return {
      importType_: "excel",
      filesObj: {
        formData: {},
        fileName: "",
      },
      separator: "",
      currentFileType: this.acceptFileType,
      thousandthTypeList: [",", ";", ".", "/", "|"],
    };
  },
  watch: {
    importType_: {
      handler(val) {
        if (val === "csv") {
          this.currentFileType = ".csv";
        } else {
          this.currentFileType = ".xls,.xlsx";
        }
      },
      immediate: true,
    },
  },
  methods: {
    changeType() {
      this.filesObj = {
        formData: {},
        fileName: "",
      };
      this.separator = "";
    },
    beforeUpload(file) {
      //文件类型
      if (this.importType_ === "excel") {
        const isTypeXSL = file.type === "application/vnd.ms-excel"; //XSL文件
        const isTypeXSLS =
          file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"; //XSLX文件
        const fileType = isTypeXSL || isTypeXSLS;
        if (!fileType) {
          this.$message.error(this.$t("importDialog.only updalod xls/xlsx file"));
          return;
        }

        if (file) {
          this.filesObj.fileName = file.name;
        }
        // 文件大小限制为10M
        // const fileLimit = file.size / 1024 / 1024 < 10;
        // if (!fileLimit) {
        //   this.$message.error("上传文件大小不超过10M！");
        // }
      } else {
        // todo: 校验
        console.log("beforeUploadfile======", file);
        if (file.type !== "text/csv") {
          this.$message.error(this.$t("importDialog.only updalod csv file"));
          return;
        }
        if (file) {
          this.filesObj.fileName = file.name;
        }
      }
    },
    getSepVal(val) {
      this.separator = val
      this.filesObj.formData.delete("delimiter");
    },
    uploadHttpRequest(file) {
      if (this.source === "itemPage") {
        const formData = new FormData();
        console.log('formData====',formData)
        formData.append("file", file.file);
        // formData.append("delimiter", this.separator);
        formData.append("scenarioId", this.scenarioId);
        this.filesObj.formData = formData;
      } else {
        const formData = new FormData();
        formData.append("file", file.file);
        this.filesObj.formData = formData;
      }
    },
    async handelSubmit() {
      if (!this.filesObj.fileName) {
        this.$message.warning(this.$t("importDialog.File has not been uploaded"));
        return;
      }
      if (this.source === "itemPage") {
        if (this.importType_ === "csv" && this.separator === "") {
          this.$message.warning(this.$t("importDialog.please chose separator"));
          return;
        }
        this.filesObj.formData.append("delimiter", this.separator);
        this.$emit("handelSubmit", this.filesObj.formData);
      } else {
        this.$emit("handelSubmit", this.filesObj);
      }
    },
    handelCancel() {
      this.$emit("handelCancel");
      this.filesObj = {
        formData: {},
        fileName: "",
      };
      this.separator = "";
      this.importType_ = "excel";
    },
  },
  created() {
    this.importType_ = this.importType;
  },
};
</script>
<style lang="scss" scoped>
.flex_ {
  display: flex;
}
.flex-column-center {
  align-items: center;
}
.flex-row-center {
  justify-content: center;
}

.import_dialog ::v-deep {
  .upload-item {
    margin-top: 40px;
    .label {
      font-size: 14px;
      color: #666666;
      margin-right: 14px;
      margin-top: 9px;
      text-align: right;
      width: 100px;
    }
    .el-button {
      width: 300px;
      height: 34px;
      background: #f6f6f6 !important;
      border: 1px solid #dddddd !important;
      border-radius: 4px !important;
      flex: 1;
      color: #666666;
      font-size: 14px;
    }
    .el-button > span {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .el-upload-list {
      display: none;
    }
    .select-file-text {
      font-size: 14px;
    }
  }
}
.csv_character ::v-deep {
  margin-top: 20px;
  .label {
    font-size: 14px;
    color: #666666;
    margin-right: 14px;
    height: 34px;
    line-height: 34px;
    display: inline-block;
    text-align: right;
    width: 100px;
  }
  .el-input {
    width: 300px;
  }
}

.dialog-footer ::v-deep {
  text-align: center;
  .el-button {
    width: 110px;
    height: 40px;
  }
  .el-button.cancel-btn {
    background: #d4d4d4 !important;
    box-shadow: 0px 1px 15px 0px rgba(99, 99, 99, 0.23);
    color: #626262;
  }
  .el-button.save-btn {
    background: #35b871 !important;
    box-shadow: 0px 1px 15px 0px rgba(53, 184, 113, 0.55);
    color: #ffffff;
  }
}
</style>