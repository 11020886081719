import router from "../axios";
import config from "../../config/index"
const { baseUrl } = config;

function getDistributeMerchantList(data) {
  // 获取分配商户基站列表
  return router({
    url: baseUrl+"coor/v1/getDistributeMerchantList",
    data: data,
    method: "POST",
  });
}


function agentMerchantList(data) {
  // 获取当前用户所属代理商下所有商户列表
  return router({
    url: baseUrl+"merchant/v1/agentMerchantList",
    data: data,
    method: "POST",
  });
}
// 
function updateMerchant(data) {
  // 更新基站商户
  return router({
    url: baseUrl+"coor/v1/switchCoorMerchant",
    data: data,
    method: "POST",
  });
}

function getDistributeStoreList(data) {
  // 获取分配门店基站列表
  return router({
    url: baseUrl+"coor/v1/getDistributeStoreList",
    data: data,
    method: "POST",
  });
}

function switchPage(data) {
  // 切换门店获取当前门店所有下级门店
  return router({
    url: baseUrl+"store/v1/switchPage",
    data: data,
    method: "POST",
  });
}
//

function switchCoorStore(data) {
  // 切换门店获取当前门店所有下级门店
  return router({
    url: baseUrl+"coor/v1/switchCoorStore",
    data: data,
    method: "POST",
  });
}


// 基站升级部分
function getUpgradeApList(data) {
  // 获取基站升级列表
  return router({
    url: baseUrl+"coor/v1/getUpgradeApList",
    data: data,
    method: "POST",
  });
}

function getCoorStatus(data) {
  // 获取基站升级列表
  return router({
    url: baseUrl+"code/v1/getList",
    data: data,
    method: "POST",
  });
}

function uploadPkg(data) {
  // 上传安装包
  return router({
    url: baseUrl+"file/v1/upload/pkg",
    data: data,
    method: "POST",
  });
}

function upgrade(data) {
  // 批量升级
  return router({
    url: baseUrl+"coor/v1/upgrade",
    data: data,
    method: "POST",
  });
}

function getUpgradeLogList(data) {
  // 升级记录
  return router({
    url: baseUrl+"coor/v1/getUpgradeLogList",
    data: data,
    method: "POST",
  });
}
export {
  getDistributeMerchantList,
  agentMerchantList,
  updateMerchant,
  getDistributeStoreList,
  switchPage,
  switchCoorStore,
  getUpgradeApList,
  getCoorStatus,
  uploadPkg,
  upgrade,
  getUpgradeLogList
}