<template>
  <div class="select-owner-wrap">
    <el-dialog
      :visible.sync="showOwnerDialog"
      :title="$t('itemsScenarios.SelectOwner')"
      :before-close="handleOwnerClose"
      :close-on-click-modal="false"
      width='1200px'
    >
      <el-table
        ref="tableRef"
        :data="tableList"
        style="width:100%;margin-bottom: 20px;"
        row-key="loginId"
        @selection-change="handleSelectionChange"
        @sort-change="handleSort"
        @row-click="rowCheck"
       >
        <el-table-column
          width="100"
          type="selection"
        >
          <el-table-column
            width="55"
            type="selection"
            style="display:none"
            >
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('user.Store Name')"
          width="150"
          prop="storeName"
         >
          <el-table-column prop="storeName" width="150">
            <template slot="header" slot-scope="scope">
              <el-input size="mini" v-model="tableInfo.storeName" @input="getTableList" clearable/>
            </template>
          </el-table-column>
        </el-table-column>
        <!-- <el-table-column
          label="用户组"
          sortable
          width="200"
          prop="userRoleName"
         >
          <el-table-column prop="userRoleName" >
            <template slot="header" slot-scope="scope">
              <el-input size="mini" style="width: 120px" v-model="tableInfo.userRoleName" @input="getTableList" clearable/>
            </template>
          </el-table-column>
        </el-table-column> -->

        <el-table-column :label="$t('user.User Group')" prop="user_role_id" width="180" sortable="custom" header-align="center">
          <el-table-column  prop="userRoleName" width="180" align="center">
            <template slot="header" slot-scope="scope">
              <!-- <el-input v-model="searchParams.userRoleId" @input="getData" size="small" placeholder="User Group"/> -->
              <el-select v-model="tableInfo.userRoleId" placeholder=""  size="mini" @change="getTableList" clearable>
                <el-option
                  v-for="item in userGroupList"
                  :key="item.userRoleId"
                  :label="item.roleName"
                  :value="item.userRoleId"
                >
                  <div class="d-flex justify-content-between">
                    <div class="">{{item.roleName}}</div>
                    <div style="color:#ccc">{{item.storeName}}</div>
                  </div>
                </el-option>
              </el-select>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column
          :label="$t('user.User Account')"
          sortable="custom"
          width="150"
          prop="login_id"
         >
          <el-table-column prop="loginId" width="150">
            <template slot="header" slot-scope="scope">
              <el-input size="mini" v-model="tableInfo.loginId" @input="getTableList" clearable/>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('user.User Name')"
          prop="user_name"
          sortable="custom"
          width="150"
         >
          <el-table-column prop="userName" width="150">
            <template slot="header" slot-scope="scope">
              <el-input size="mini" v-model="tableInfo.userName" @input="getTableList" clearable/>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          :label="$t('user.Creation Time')"
          sortable="custom"
          min-width="200"
          prop="create_time"
         >
          <el-table-column prop="createTime" min-width="200">
            <template slot="header" slot-scope="scope">
              <!-- <el-input size="mini" style="width: 120px" v-model="tableInfo.createTime" @input="getTableList" clearable/> -->
              <el-date-picker
                size="small"
                clearable
                v-model="tableInfo.startEndDate"
                type="daterange"
                placement="bottom-start"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                @input="getTableList"
              />
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <pagination :tableInfo="tableInfo" @changePage="changePage" />
      <span slot="footer" class="dialog-footer">
        <el-button class="cancel-btn" @click="showOwnerDialog = false">{{$t('button.Cancel')}}</el-button>
        <el-button class="save-btn" type="success" @click="save()">{{$t('button.Confirm')}}</el-button>
      </span>

    </el-dialog>
  </div>
</template>
<script>
import { userGetList } from "@/libs/api/system-management-user";
import { debounce } from 'lodash';
import { getRoleByStoreId } from "@/libs/api/system-management-user-goup"
import pagination from '@/components/pagination'

export default {
  data() {
    return {
      showOwnerDialog: false,
      ownerInfo: {},
      tableList: [],
      checkList:[],
      userGroupList: [],// 用户组list
      tableInfo:{
        storeName: '',
        userRoleName:'',
        updateUser: '',
        userName: '',
        userId:'',
        userRoleId:'',
        startEndDate: [],
        state: 0,
        loginId: '',
        current: 1,
        size: 10,
        total: 0,
        orders: []
      },
      isCanSelectEvent: true // 点击表格当前行时，handleSelectionChange和rowCheck时间都触发问题，添加属性判断
    }
  },
  components:{
    pagination
  },
  props: {
    dialogVisible: {
      default: false,
      type: Boolean,
    },
    storeType: {
      default: '',
      type: String,
    }
  },
  methods: {
    changePage() {
      this.getTableList()
    },
    // 表格排序
    handleSort(column, prop, order){
      console.log('表格排序',column, prop, order)
      let arr = []
      if(column.order){
        let str1= column.order === "ascending" ? "asc" : "desc";
        str1 = str1 + ':'+ column.prop;
        arr.push(str1)
      } else {
        arr = []
      }
      
      this.tableInfo.orders = arr;
      this.getTableList()
    },
    show(){
      this.isCanSelectEvent = true
      this.showOwnerDialog = true
      this.tableList = []
      this.checkList = []
      this.userRoleGetList()
      this.getTableList()
    },
    // 获取用户组信息User Group---获取当前用户下所有角色
    async userRoleGetList(){
      let params ={
        storeId: localStorage.getItem('storeId') || '',
        storeType: this.storeType ,//门店类型，0：所有门店，1：子级门店 2：当前门店
      }
      try {
        const res = await getRoleByStoreId(params)
        let response = res.data
        if(response.state){
          this.userGroupList = response.data || []
        } else{
          this.$message.error(response.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    getTableList:debounce(async function(){
      const params = {
        userName: this.tableInfo.userName,
        storeName: this.tableInfo.storeName,
        storeId: localStorage.getItem('storeId') || '',
        storeType: this.storeType,
        isEffect: 0,
        loginId: this.tableInfo.loginId,
        startDate: this.tableInfo.startEndDate && this.tableInfo.startEndDate[0],
        endDate: this.tableInfo.startEndDate && this.tableInfo.startEndDate[1],
        state: this.tableInfo.state,
        userId: this.tableInfo.userId,
        userRoleId: this.tableInfo.userRoleId,
        current: this.tableInfo.current,
        size: this.tableInfo.size,
        orders: this.tableInfo.orders
      }
      const res = await userGetList(params)
       if (res.status === 200 && res?.data?.code === 1) {
         this.tableList = res.data?.data?.records
         this.tableInfo.total = res.data?.data?.total
       } else {
         this.$message.warning(res?.data?.message)
       }
    }, 1000),
    handleSelectionChange(val) {
      if (this.isCanSelectEvent) {
        console.log('handleSelectionChange===', val)
        val.forEach(item => {
          this.checkList.push(item.loginId)
        });
      }
    },
    removeELement(arr, element) {
      var index = arr.indexOf(element); 
      if (index > -1) { 
        arr.splice(index, 1); 
      }
      return arr
    },
    rowCheck(val) {
      this.isCanSelectEvent = false
      console.log('rowCheck====', val)
      if (val && this.checkList.includes(val.loginId)) {
        this.checkList = this.removeELement(this.checkList, val.loginId)
        this.$refs.tableRef.toggleRowSelection(val, false);
      } else {
        this.checkList.push(val.loginId)
        this.$refs.tableRef.toggleRowSelection(val, true);
      }
    },
    handleOwnerClose() {
      this.showOwnerDialog = false
      this.tableInfo = {}
    },
    save() {
      this.$emit('onOwnerConfirm', this.checkList)
      this.showOwnerDialog = false
    },
  }
}
</script>
