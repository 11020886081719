import router from "../axios";

import config from "../../config/index"
const { baseUrl } = config;

// 获取用户列表
function userGetList(data) {
  return router({
    url: baseUrl+"user/v1/getList",
    data:data,
    method: "POST",
  });
}

// 获取机构用户列表
function getAgentUserList(data) {
  return router({
    url: baseUrl+"user/v1/getAgentUserList",
    data:data,
    method: "POST",
  });
}

// 创建门店用户
function userCreateStoreUser(data) {
  return router({
    url: baseUrl+"user/v1/createStoreUser",
    data:data,
    method: "POST",
  });
}

// 用户信息修改
function userUpdateStoreUser(data) {
  return router({
    url: baseUrl+"user/v1/updateStoreUser",
    data:data,
    method: "POST",
  });
}
// 机构用户修改
function userUpdateAgentUser(data) {
  return router({
    url: baseUrl+"user/v1/updateAgentUser",
    data:data,
    method: "POST",
  });
}

// 获取用户信息
function userGet(data) {
  return router({
    url: baseUrl+"user/v1/get",
    data:data,
    method: "POST",
  });
}

// 逻辑删除用户信息
function userDelete(data) {
  return router({
    url: baseUrl+"user/v1/delete",
    data:data,
    method: "POST",
  });
}

// 重置密码
function resetPassword(data) {
  return router({
    url: baseUrl+"user/v1/resetPassword",
    data:data,
    method: "POST",
  });
}

// 获取当前用户下所有角色
function getAllDescendantRole(data) {
  return router({
    url: baseUrl+"userRole/v1/getAllDescendantRole",
    data:data,
    method: "POST",
  });
}

// 获取当前用户下所有角色 
function getAllRoleByMerchantId(data) {
  return router({
    url: baseUrl+"userRole/v1/getAllRoleByMerchantId",
    data:data,
    method: "POST",
  });
}

export {
  userGetList,
  getAgentUserList,
  userCreateStoreUser,
  userUpdateStoreUser,
  userUpdateAgentUser,
  userGet,
  userDelete,
  resetPassword,
  getAllDescendantRole,
  getAllRoleByMerchantId
}